.serieslist {
    @include flex(flex-start);

    flex-wrap: wrap;

    &__item {
        position: relative;

        &__in {
            position: relative;
        }

        &__img {
            margin-bottom: 10px;
            height: 200px;
            position: relative;
            background-color: #fff;

            .hoverzoom {
                height: 100%;
            }

            .btn {
                transition: 0.5s ease opacity;

                @include absolute(null 50% 10px null);

                transform: translate(50%, 0);
                background-color: #fff;
                z-index: 5;
                opacity: 0;

                &:hover {
                    background-color: $c-main;
                }
            }

            img {
                @include absolute-center;

                max-height: 100%;
                width: auto;
            }
        }

        &__more {
            transition: 0.5s ease opacity;

            @include absolute(0 0 0 100%);

            width: 100%;
            padding: 15px;
            z-index: 14;
            opacity: 0;
            pointer-events: none;
        }
    }

    @include media($break768M) {
        &__item {
            width: calc(100% / 2 - 5px);
            margin-right: 10px;
            margin-bottom: 15px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }


    @include media($break992M) {
        &__item {
            .attrs__btn {
                position: relative;
                z-index: 10;
            }
        }
    }


    @include media($break768-992M) {
        &__item {
            width: calc(100% / 3 - 15px);
            margin-right: 22px;
            margin-bottom: 22px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }


    @include media($break992) {
        &__item {
            &--morehover {
                &:before {
                    content: "";

                    @include absolute((-10px) null -25px -10px);

                    width: calc(200% + 35px);
                    background-color: $c-bg;
                    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
                    z-index: 10;
                    opacity: 0;
                    pointer-events: none;
                    transition: 0.5s ease opacity;
                }

                &.is-hoverdelay,
                &.is-tippy-hover {
                    &:before {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    .serieslist__item {
                        &__img .btn {
                            opacity: 1;
                        }

                        &__more {
                            opacity: 1;
                            pointer-events: auto;
                        }

                        &__carousel {
                            pointer-events: auto;

                            .carousel__arrows,
                            .slick-dots {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.is-hover,
                &.is-tippy-hover {
                    &:before {
                        //

                    }

                    .serieslist__item {
                        &__img {
                            .btn {
                                display: block;
                            }
                        }

                        &__in {
                            z-index: 15;
                        }

                        &__more {
                            //

                        }

                        &__carousel {
                            //

                        }
                    }

                    &.offsethover-is-active {
                        &:before {
                            right: -10px;
                            left: auto;
                        }

                        .serieslist__item {
                            &__more {
                                left: calc(-100% - 20px);
                                width: calc(100% + 20px);
                            }
                        }
                    }
                }
            }
        }
    }


    @include media($break992-1200M) {
        &__item {
            width: calc(100% / 4 - 15px);
            margin-right: 20px;
            margin-bottom: 30px;

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }


    @include media($break1200-1500M) {
        &__item {
            width: calc(100% / 5 - 20px);
            margin-right: 25px;
            margin-bottom: 25px;

            &:nth-child(5n) {
                margin-right: 0;
            }
        }
    }


    @include media($break1500) {
        &__item {
            width: calc(100% / 6 - 40px);
            margin-right: 40px;
            margin-bottom: 40px;

            &__more {
                width: calc(100% + 80px);
            }

            &--morehover:before {
                width: calc(200% + 80px);
            }

            &:nth-child(6n) {
                margin-right: 0;
            }

            &--morehover {
                &.is-hover.offsethover-is-active {
                    &:before {
                        right: -10px;
                        left: auto;
                    }

                    .serieslist__item {
                        &__more {
                            left: calc(-100% - 70px);
                            width: calc(100% + 80px);
                        }
                    }
                }
            }
        }
    }
}
