.slick-slider {
    > div,
    > article {
        display: none;

        &:first-child {
            display: block;
        }
    }

    &.slick-initialized {
        > div,
        > article {
            display: block;
        }
    }
}
