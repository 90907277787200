/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*# sourceMappingURL=bootstrap-reboot.min.css.map */
body {
  margin: 0;
  color: #202020;
  font-family: "Roboto", "Arial";
  font-size: 14px;
  line-height: 1.4;
  background-color: #fff;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  color: #202020;
}
@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

a {
  color: #0a89ae;
  text-decoration: underline;
}
a:hover {
  color: #06a2d0;
}

img {
  max-width: 100%;
  height: auto;
}

.upper {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.hide {
  display: none;
}

@media (max-width: 767px) {
  .hide--mobile {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hide--tablet {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hide--desktop {
    display: none !important;
  }
}
.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-1 {
  margin-top: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mt-7 {
  margin-top: 56px;
}

.mb-7 {
  margin-bottom: 56px;
}

.mt-8 {
  margin-top: 64px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mt-9 {
  margin-top: 72px;
}

.mb-9 {
  margin-bottom: 72px;
}

.mt-10 {
  margin-top: 80px;
}

.mb-10 {
  margin-bottom: 80px;
}

.mt-11 {
  margin-top: 88px;
}

.mb-11 {
  margin-bottom: 88px;
}

.mt-12 {
  margin-top: 96px;
}

.mb-12 {
  margin-bottom: 96px;
}

.mt-13 {
  margin-top: 104px;
}

.mb-13 {
  margin-bottom: 104px;
}

.mt-14 {
  margin-top: 112px;
}

.mb-14 {
  margin-bottom: 112px;
}

.mt-15 {
  margin-top: 120px;
}

.mb-15 {
  margin-bottom: 120px;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10420;
  overflow: hidden;
  position: fixed;
  background: #000000;
  opacity: 0.9;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10430;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 10450;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 5px;
  top: -9px;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* overlay at start */
.mfp-fade .mfp-arrow {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* arrows animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-arrow {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* arrows animate it */
.mfp-fade.mfp-wrap.mfp-removing .mfp-arrow {
  opacity: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: 0;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric-required .selectric .label .required-sign {
  display: inline-block;
}

.selectric {
  width: 270px;
  display: flex;
  background: #0e88af;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #036b88;
  height: 40px;
  box-shadow: inset 2px 2px 5px 0 #056a88;
}
.selectric.error {
  border: 2px red solid;
}
.selectric.value-selected .label {
  color: #2d2c2a;
}
.selectric.value-selected .required-sign {
  display: none !important;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  padding: 0 20px;
  color: #fff;
  font-size: 15px;
  line-height: 40px;
  font-weight: normal;
}
.selectric .label span {
  /*color: $orange;
  font-family: muli_bold;*/
}
.selectric .label .required-sign {
  display: none;
  margin-left: 4px;
  color: red;
  font-style: normal;
}
.selectric .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background-color: #0e84a8;
  border-left: 1px #08789e solid;
  margin-left: auto;
}
.selectric .arrow-button {
  width: 16px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
  background: url("../images/icons/chevron-down.svg");
}

.selectric-open {
  z-index: 20;
}
.selectric-open .selectric-items {
  display: block;
}
.selectric-open .button {
  transform: rotate(180deg);
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% - 15px);
  left: 0;
  background: #fff;
  z-index: -1;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  border: 1px solid #036b88;
  border-top: 0;
  padding-top: 15px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 6px 15px;
  cursor: pointer;
  border-bottom: 1px #dedede solid;
  border-left: 3px transparent solid;
}
.selectric-items li:hover, .selectric-items li.selected, .selectric-items li.highlighted {
  background-color: #e7e7e7;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: inherit;
  fill: currentColor;
  position: relative;
}
.icon-logo-flowa {
  height: 51px;
  width: 117px;
}
.icon-togglenav {
  height: 33px;
  width: 38px;
}
.icon-arrow-down, .icon-arrow-down_dark {
  height: 7px;
  width: 12px;
}
.icon-arrow-right {
  height: 11px;
  width: 6px;
}
.icon-arrow-right_text {
  height: 7px;
  width: 5px;
}
.icon-arrow-more {
  height: 4px;
  width: 8px;
}
.icon-heart, .icon-heart-fill, .icon-heart-blue, .icon-heart-white {
  height: 18px;
  width: 21px;
  stroke: currentColor;
  fill: none;
}
.icon-heart.icon--filled, .icon-heart-fill.icon--filled, .icon-heart-blue.icon--filled, .icon-heart-white.icon--filled {
  fill: currentColor;
}
.icon-history {
  height: 19px;
  width: 21px;
}
.icon-eye {
  height: 19px;
  width: 18px;
}
.icon-search {
  height: 19px;
  width: 19px;
}
.icon-quote {
  height: 28px;
  width: 35px;
}
.icon-calendar {
  height: 15px;
  width: 14px;
}
.icon-facebook {
  height: 20px;
  width: 11px;
}
.icon-google {
  height: 20px;
  width: 20px;
}
.icon-tip {
  height: 17px;
  width: 20px;
}
.icon-share-fb, .icon-share-tw, .icon-share-email {
  height: 40px;
  width: 40px;
}
.icon-arrow-carousel, .icon-arrow-carousel_blue {
  height: 26px;
  width: 14px;
}
.icon-fullscreen {
  height: 10px;
  width: 10px;
}
.icon-help, .icon-info {
  height: 17px;
  width: 17px;
  fill: #000;
}
.icon-pencil {
  height: 14px;
  width: 14px;
}
.icon-share {
  height: 13px;
  width: 12px;
}
.icon-trash {
  height: 14px;
  width: 8px;
}
.icon-add {
  height: 50px;
  width: 50px;
}
.icon-save {
  height: 11px;
  width: 10px;
}
.icon-save.icon--large {
  height: 15px;
  width: 15px;
}
.icon-inbox {
  height: 11px;
  width: 13px;
}
.icon-close, .icon-close-dark {
  height: 8px;
  width: 8px;
}
.icon-close-thin {
  height: 21px;
  width: 21px;
}
.icon-pin {
  height: 61px;
  width: 82px;
}
.icon-check {
  height: 7px;
  width: 10px;
}
.icon-blank {
  height: 8px;
  width: 10px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
}
.actions__item {
  margin-right: 10px;
  cursor: pointer;
  margin: 0;
  border: 0;
  padding: 0;
  background: none;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: 0.3s ease all;
}
.actions__item:last-child {
  margin-right: 0;
}
.actions__item--trash {
  color: #dc0c23;
}
.actions__item--trash:hover {
  color: #dc0c23;
}
@media (min-width: 992px) {
  .actions__item:hover:not(.hovernav) {
    transform: scale(1.2);
  }
}

@media (min-width: 992px) {
  .animate__item:nth-child(2) {
    transition-delay: 0.1s;
  }
  .animate__item:nth-child(3) {
    transition-delay: 0.2s;
  }
  .animate__item:nth-child(4) {
    transition-delay: 0.3s;
  }
  .animate__item:nth-child(5) {
    transition-delay: 0.4s;
  }
  .animate__item:nth-child(6) {
    transition-delay: 0.5s;
  }
  .animate__item:nth-child(7) {
    transition-delay: 0.6s;
  }
  .animate__item:nth-child(8) {
    transition-delay: 0.7s;
  }
  .animate__item:nth-child(9) {
    transition-delay: 0.8s;
  }
  .animate--2row:nth-child(even) {
    transition-delay: 0.2s;
  }
  .animate--fall, .animate--fall-long {
    opacity: 0;
    transform: translate(0, -10px);
    transition: 0.5s ease transform, 0.5s ease opacity;
  }
  .animate--fall.is-visible, .is-visible .animate--fall, .animate--fall-long.is-visible, .is-visible .animate--fall-long {
    transform: translate(0);
    opacity: 1;
  }
  .animate--fall-long {
    transform: translate(0, -50px);
  }
  .animate--slidein {
    opacity: 0;
    transform: translate(-5%, 0);
    transition: 0.5s ease transform, 0.5s ease opacity;
  }
  .animate--slidein.is-visible, .is-visible .animate--slidein {
    transform: translate(0);
    opacity: 1;
  }
  .animate--pop {
    opacity: 0;
    transform: scale(1.2);
    transition: 0.5s ease transform, 0.5s ease opacity;
  }
  .animate--pop.is-visible, .is-visible .animate--pop {
    transform: scale(1);
    opacity: 1;
  }
  .animate--fade {
    opacity: 0;
    transition: 0.3s ease transform, 0.3s ease opacity;
  }
  .animate--fade.is-visible, .is-visible .animate--fade {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) translateZ(0);
  }
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}
@keyframes dot {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.article__item__img {
  overflow: hidden;
}
.article__item__img img {
  transform: scale(1);
  transition: 0.3s ease all;
}
.article__item__date {
  margin: 10px 0 -5px;
}
.article__item__title.title {
  margin: 10px 0 2px;
}
.article__item__desc svg {
  margin-left: 5px;
  transition: 0.3s ease all;
}
.article__item--hover {
  transition: 0.3s ease all;
}
.article__item--hover:hover .article__item__img img {
  transform: scale(1.05);
}
.article__item--hover:hover .icon-arrow-right_text {
  transform: translate(5px, 0);
}

.attrs {
  line-height: 1.6;
}
.attrs__light {
  color: #706f6f;
}
.attrs__light a {
  color: currentColor;
  text-decoration: none;
  transition: 0.3s ease all;
}
.attrs__light a:hover {
  color: #0a89ae;
}
.attrs__eye {
  margin-left: 5px;
}
.attrs__title {
  font-size: 12px;
  font-weight: bold;
}
.attrs__price {
  font-size: 16px;
  font-weight: bold;
}
.attrs__discountprice {
  color: #0a89ae;
}
.attrs__oldprice {
  color: #706f6f;
  text-decoration: line-through;
}
.attrs__btn {
  margin-top: 10px;
}
.attrs__row span {
  color: #706f6f;
  padding-right: 5px;
}
.attrs__group {
  margin-bottom: 30px;
}
.attrs--offsetbtn .attrs__btn {
  margin-top: 30px;
}
.attrs--compact {
  line-height: 1.4;
}
@media (max-width: 767px) {
  .attrs {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .attrs__title {
    font-size: 14px;
  }
  .attrs__price {
    font-size: 20px;
  }
}

.blog__header {
  background-color: #F4F4F4;
}
.blog__header__img {
  max-width: 1100px;
  width: 100%;
}
.blog__content {
  position: relative;
}
.blog__content__date {
  margin: 20px 0;
}
.blog__content__in {
  max-width: 900px;
}
.blog__content__gallery {
  margin-top: 30px;
}
.blog__content__perex {
  font-size: 20px;
}
@media (max-width: 767px) {
  .blog__content__gallery {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .blog__content {
    padding-left: 110px;
  }
  .blog__content__gallery {
    margin-top: 60px;
  }
  .blog__content__share {
    position: absolute;
    top: 43px;
    left: 25px;
  }
}

.bloglist__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s ease all;
}
.bloglist__item__img {
  margin-right: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.bloglist__item__img img {
  transition: 0.3s ease all;
}
.bloglist__item__title.title {
  margin: 5px 0 2px;
}
.bloglist__item__desc svg {
  margin-left: 5px;
  transition: 0.3s ease all;
}
.bloglist--nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  border-top: 1px solid #e2e2e2;
  padding: 30px 0;
}
.bloglist--nav .bloglist__item {
  max-width: 530px;
}
.bloglist--nav .bloglist__item:last-child {
  text-align: right;
}
.bloglist--nav .bloglist__item:last-child img {
  order: 2;
  margin-right: 0;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .bloglist__item {
    width: 50%;
  }
  .bloglist__item__desc {
    font-size: 13px;
  }
  .bloglist__item__img {
    display: none;
  }
}
@media (min-width: 768px) {
  .bloglist--nav {
    padding: 60px 0 70px;
  }
}
@media (min-width: 992px) {
  .bloglist__item:hover .bloglist__item__img img {
    transform: scale(1.05);
  }
  .bloglist__item:hover .icon-arrow-right_text {
    transform: translate(5px, 0);
  }
}

.btn {
  display: inline-block;
  text-decoration: none;
  outline: none !important;
  border: 0;
  line-height: 1.3;
  cursor: pointer;
  transition: 0.3s ease all;
}
.btn:hover {
  text-decoration: none;
}
.btn--dropdown {
  box-shadow: inset 2px 2px 3px rgba(17, 16, 13, 0.2);
  border: 1px solid #056b88;
  background-color: #0a89ae;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}
.btn--dropdown .btn__label {
  padding: 10px 20px 9px 15px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.btn--dropdown .btn__label img {
  margin-right: 7px;
}
.btn--dropdown .btn__icon {
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  width: 40px;
  border-left: 1px solid #056b88;
  transition: 0.3s ease all;
}
.btn--dropdown .btn__icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: 0.3s ease all;
}
.btn--dropdown--light {
  background: none;
  box-shadow: none;
  border: 1px solid #d7d7d7;
}
.btn--dropdown--light .btn__label {
  color: #1f1f1f;
}
.btn--dropdown--light .btn__icon {
  background: none;
  border: none;
}
.is-toggled .btn--dropdown {
  border-radius: 20px 20px 0 0;
  box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
}
.btn--text {
  font-size: 16px;
}
.btn--text span {
  text-decoration: underline;
}
.btn--text svg {
  margin-right: 10px;
}
.btn--text--iconright svg {
  margin-right: 0;
  margin-left: 10px;
}
.btn--wishlist, .btn--download {
  border: 1px solid #0a89ae;
  color: #0a89ae;
  line-height: 1.1;
  border-radius: 20px;
  font-size: 12px;
}
.btn--wishlist .btn__in, .btn--download .btn__in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.btn--wishlist .btn__label, .btn--download .btn__label {
  padding: 9px 8px 8px 5px;
  font-weight: bold;
}
.btn--wishlist .btn__icon, .btn--download .btn__icon {
  margin-left: 10px;
  position: relative;
  bottom: 1px;
}
.btn--wishlist--fill, .btn--download--fill {
  background-color: #0a89ae;
  color: #fff;
}
.btn--primary {
  background-color: #0a89ae;
  color: #fff;
  border-radius: 20px;
}
.btn--primary .btn__in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.btn--primary .btn__label {
  padding: 11px 30px 10px;
  font-weight: bold;
}
.btn--primary .btn__icon {
  width: 50px;
  position: relative;
  margin-left: -30px;
  transition: 0.3s ease all;
}
.btn--primary .btn__icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.btn--primary--gray {
  background-color: #706f6f;
}
.btn--primary--arrowdown .btn__icon {
  transform: rotate(90deg);
}
.btn--primary.btn--iconcenter {
  padding-left: 30px;
  padding-right: 20px;
}
.btn--primary.btn--iconcenter .btn__label {
  padding-left: 0;
  padding-right: 0;
}
.btn--primary.btn--iconcenter .btn__icon {
  margin-left: 0;
  width: 30px;
}
.btn--primary.btn--iconcenter .btn__in {
  justify-content: center;
}
.btn--primary:hover {
  color: #fff;
}
.btn--social {
  color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 285px;
}
.btn--social .btn__in {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.btn--social .btn__label {
  padding: 11px 10px 10px;
  font-weight: bold;
}
.btn--social .btn__icon {
  width: 26px;
  margin-left: 15px;
  text-align: center;
}
.btn--social--facebook {
  background-color: #3A5C97;
}
.btn--social--google {
  background-color: #DF2D23;
}
.btn--add {
  text-decoration: underline;
}
.btn--full {
  width: 100%;
  text-align: center;
}
.btn--full .btn__label {
  display: block;
  margin: 0 auto;
}
.btn--wide .btn__label {
  padding-left: 30px;
  padding-right: 30px;
}
.btn--wide.btn--primary--arrowdown .btn__in {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.btn--wide.btn--primary--arrowdown .btn__label {
  padding: 11px 10px 10px 60px;
  font-weight: bold;
}
.btn--wide.btn--primary--arrowdown .btn__icon {
  width: 26px;
  margin-left: 5px;
  text-align: center;
  margin-right: 60px;
}
.btn--wide2x .btn__label {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 767px) {
  .btn--wishlist svg {
    max-width: 16px;
  }
}
@media (min-width: 768px) {
  .btn--primary.btn--iconcenter {
    padding-left: 70px;
    padding-right: 60px;
  }
  .btn--wishlist, .btn--download {
    font-size: 16px;
    white-space: nowrap;
  }
  .btn--wishlist .btn__label, .btn--download .btn__label {
    padding: 9px 15px 8px 10px;
  }
  .btn--wishlist .btn__icon, .btn--download .btn__icon {
    margin-left: 12px;
  }
  .btn--wide .btn__label {
    padding-left: 70px;
    padding-right: 70px;
  }
  .btn--wide2x .btn__label {
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media (min-width: 992px) {
  .btn--primary:hover {
    background-color: #06a2d0;
  }
  .btn--primary:hover .btn__icon {
    transform: translate(5px, 0);
  }
  .btn--primary:hover.btn--primary--arrowdown .btn__icon {
    transform: translate(0, 3px) rotate(90deg);
  }
  .btn--dropdown:hover {
    color: #fff;
    text-decoration: none;
  }
  .btn--dropdown:hover .btn__icon {
    background-color: rgba(255, 255, 255, 0.07);
  }
  .btn--download:hover {
    background-color: #0a89ae;
    color: #fff;
  }
  .btn--download--fill:hover {
    background-color: #06a2d0;
  }
  .btn--dropdown--light:hover .btn__icon {
    transform: translate(0, 2px);
  }
  .btn--social:hover {
    color: #fff;
  }
  .btn--social:hover.btn--social--facebook {
    background-color: #4873bd;
  }
  .btn--social:hover.btn--social--google {
    background-color: #f73227;
  }
}

.btnbox {
  margin: 40px 0;
}

.carousel__wrapper {
  position: relative;
}
.carousel__arrows {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 100;
  transform: translate(0, -50%);
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
}
.carousel__arrows__item {
  pointer-events: auto;
  cursor: pointer;
  padding: 20px 10px;
  background-color: #000;
}
.carousel__arrows__item:first-child {
  transform: rotate(-180deg);
}
.carousel__arrows__item.slick-disabled {
  opacity: 0.5;
}
.carousel__core {
  position: relative;
}
.carousel__core .slick-dots {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  position: absolute;
  right: 0;
  bottom: 7px;
  left: 0;
  pointer-events: none;
}
.carousel__core .slick-dots button {
  margin: 0 3px;
  padding: 0;
  border: none;
  font-size: 0;
  height: 7px;
  width: 7px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.3;
  outline: 0;
}
.carousel__core .slick-dots .slick-active button {
  opacity: 1;
}
.carousel__core .slick-track {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: row;
}
.carousel__core .slick-slide {
  height: auto;
  position: relative;
}
.carousel__core .slick-slide.quote__item {
  padding-bottom: 130px;
}
.carousel__core .slick-slide .quote__item__profile {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 40px;
  min-height: 133px;
}
.carousel--nobg .carousel__core:before {
  display: none;
}
.carousel--nobg .carousel__arrows {
  left: 10px;
  right: 10px;
}
@media (max-width: 767px) {
  .carousel__arrows {
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .carousel__arrows {
    left: -35px;
    right: -15px;
  }
  .carousel__arrows--full {
    left: 0;
    right: 0;
    padding: 0 10px;
    z-index: 1000;
  }
  .carousel__core:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 160px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    z-index: 100;
    pointer-events: none;
  }
}
@media (min-width: 992px) {
  .carousel__arrows__item.prev:hover svg {
    transform: translate(2px, 0);
  }
  .carousel__arrows__item.next:hover svg {
    transform: translate(2px, 0);
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  user-select: none;
  margin: 0;
}
.checkbox__icon {
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.checkbox__icon input {
  position: absolute;
  top: -100px;
  right: -100px;
  bottom: -100px;
  left: -100px;
}
.checkbox__icon__square {
  height: 22px;
  width: 22px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  transition: 0.3s ease all;
}
.checkbox__icon__square:before, .checkbox__icon__square:after {
  content: "";
  height: 3px;
  background-color: #0a89ae;
  opacity: 0;
  transition: 0.3s ease all;
}
.checkbox__icon__square:before {
  width: 6px;
  position: absolute;
  top: 9px;
  left: 5px;
  transform: rotate(40deg);
}
.checkbox__icon__square:after {
  width: 10px;
  position: absolute;
  top: 8px;
  left: 7px;
  transform: rotate(-40deg);
}
.checkbox__icon input:checked + .checkbox__icon__square:before, .checkbox__icon input:checked + .checkbox__icon__square:after {
  opacity: 1;
}
.checkbox__label {
  font-size: 14px;
}
.checkbox__label span {
  margin-right: 5px;
}
.checkbox__label small {
  color: #706f6f;
  font-size: 14px;
  padding-left: 5px;
}
.checkbox:hover .checkbox__icon__square {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.checkbox.is-hidden {
  display: none;
}
.checkbox--small .checkbox__icon__square {
  height: 20px;
  width: 20px;
  border-radius: 6px;
}
.checkbox--small .checkbox__icon__square:before {
  left: 4px;
}
.checkbox--small .checkbox__icon__square:after {
  left: 6px;
}
.checkbox--labellarge .checkbox__label {
  font-size: 16px;
}
.checkbox--error {
  position: relative;
  margin-bottom: 30px;
}
.checkbox--error .checkbox__icon__square {
  border-color: #e14e5e;
}
.checkbox--error .field__error {
  padding-left: 33px;
}
.checkbox--disabled {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 767px) {
  .checkbox__icon {
    width: 35px;
  }
}

.circlebtn {
  height: 29px;
  width: 29px;
  background-color: #dc0c23;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #fff;
}
.circlebtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media (min-width: 992px) {
  .circlebtn {
    transition: 0.3s ease all;
  }
  .circlebtn:hover {
    color: #fff;
    background-color: #ff2c43;
  }
}

.colors {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
.colors__item {
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.colors__item__color {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 7px;
}
@media (max-width: 767px) {
  .colors__item {
    margin-bottom: 15px;
  }
}

.cols > .cols__item {
  position: relative;
}
@media (max-width: 767px) {
  .colsmob {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .colsmob > .cols__item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .colsmob > .cols__item--right {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: row;
  }
  .colsmob--6 > .cols__item {
    width: calc(16.6666666667% - 40px);
    margin-right: 40px;
  }
  .colsmob--6 > .cols__item:nth-child(6n) {
    margin-right: 0;
  }
  .colsmob--4 > .cols__item {
    width: calc(25% - 15px);
  }
  .colsmob--4 > .cols__item:nth-child(4n) {
    margin-right: 0;
  }
  .colsmob--3 > .cols__item {
    width: calc(33.3333333333% - 15px);
  }
  .colsmob--3 > .cols__item:nth-child(3n) {
    margin-right: 0;
  }
  .colsmob--2 > .cols__item {
    width: calc(50% - 5px);
  }
  .colsmob--2 > .cols__item:nth-child(2n) {
    margin-right: 0;
  }
  .colsmob--1 > .cols__item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  .colsmob--1 > .cols__item:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .colstablet {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .colstablet > .cols__item {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .colstablet > .cols__item--right {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: row;
  }
  .colstablet--6 > .cols__item {
    width: calc(16.6666666667% - 40px);
    margin-right: 40px;
  }
  .colstablet--6 > .cols__item:nth-child(6n) {
    margin-right: 0;
  }
  .colstablet--4 > .cols__item {
    width: calc(25% - 15px);
  }
  .colstablet--4 > .cols__item:nth-child(4n) {
    margin-right: 0;
  }
  .colstablet--3 > .cols__item {
    width: calc(33.3333333333% - 15px);
  }
  .colstablet--3 > .cols__item:nth-child(3n) {
    margin-right: 0;
  }
  .colstablet--2 > .cols__item {
    width: calc(50% - 10px);
  }
  .colstablet--2 > .cols__item:nth-child(2n) {
    margin-right: 0;
  }
  .colstablet--1 > .cols__item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  .colstablet--1 > .cols__item:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .cols {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .cols > .cols__item {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .cols > .cols__item _:-ms-fullscreen, :root .cols > .cols__item {
    margin-right: 19px;
  }
  .cols > .cols__item--right {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: row;
  }
  .cols--6 > .cols__item {
    width: calc(16.6666666667% - 40px);
    margin-right: 40px;
  }
  .cols--6 > .cols__item:nth-child(6n) {
    margin-right: 0;
  }
  .cols--4 > .cols__item {
    width: calc(25% - 15px);
  }
  .cols--4 > .cols__item:nth-child(4n) {
    margin-right: 0;
  }
  .cols--3 > .cols__item {
    width: calc(33.3333333333% - 15px);
  }
  .cols--3 > .cols__item:nth-child(3n) {
    margin-right: 0;
  }
  .cols--2 > .cols__item {
    width: calc(50% - 10px);
  }
  .cols--2 > .cols__item:nth-child(2n) {
    margin-right: 0;
  }
}

.container {
  margin: 0 auto;
  max-width: 1550px;
  width: calc(100% - 20px);
}
.container--900 {
  max-width: 900px;
}
.container--630 {
  max-width: 630px;
}
@media (min-width: 768px) {
  .container {
    width: calc(100% - 40px);
  }
}
@media (min-width: 992px) {
  .container {
    width: 98%;
  }
}

.content table td {
  vertical-align: top;
  padding-right: 90px;
}
.content table td:last-child {
  padding-right: 0;
}

.coverlink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.crossroad__list li {
  margin: 5px 0;
}
.crossroad__disabled a {
  pointer-events: none;
  color: #706f6f;
  text-decoration: none;
}

.date {
  color: #a3a3a3;
  font-size: 14px;
}
.date svg {
  margin-right: 5px;
  margin-top: -1px;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  user-select: none;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease opacity;
}
.dropdown__in {
  box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  padding: 20px 15px;
  position: relative;
}
.dropdown__in:before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-top: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  position: absolute;
  top: -8px;
  left: 50px;
  transform: rotate(-45deg);
  border-radius: 2px;
}
.dropdown__title {
  font-size: 18px;
  color: #1f1f1f;
  font-weight: bold;
  margin-bottom: 15px;
  text-decoration: none;
}
.dropdown__title span {
  font-size: 14px;
  color: #706f6f;
  padding-left: 5px;
}
.dropdown__title:hover {
  text-decoration: none;
}
.dropdown__title {
  transition: 0.3s ease all;
}
.dropdown__nav__item {
  display: block;
  text-decoration: none;
  padding: 8px 15px;
}
.dropdown__nav__item img {
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}
.dropdown__nav__item svg {
  margin-left: 7px;
}
.is-toggled .dropdown, .is-hover .dropdown {
  opacity: 1;
  pointer-events: auto;
}
.dropdown--scroll .dropdown__content {
  max-height: 500px;
  overflow: auto;
}
.dropdown--nav {
  width: 100%;
  left: 0;
  padding-top: 0;
}
.dropdown--nav .dropdown__in {
  border-radius: 0 0 20px 20px;
  border-top: 0;
  margin-top: -1px;
  padding: 0;
}
.dropdown--nav .dropdown__in:before {
  display: none;
}
.dropdown--nav--border .dropdown__nav__item {
  border-bottom: 1px solid #d7d7d7;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dropdown--nav--border .dropdown__nav__item:last-child {
  border: none;
}
@media (max-width: 767px) {
  .dropdown {
    width: 100%;
    left: 0;
  }
  .dropdown__in:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .dropdown {
    width: 430px;
    left: -50px;
    padding-top: 15px;
    z-index: 100;
  }
  .offsethover-is-active .dropdown {
    left: auto;
    right: -50px;
  }
  .offsethover-is-active .dropdown .dropdown__in:before {
    left: auto;
    right: 70px;
  }
  .dropdown__parent {
    position: relative;
  }
  .dropdown--nav {
    width: 100%;
    left: 0;
    padding-top: 0;
  }
}

.field {
  position: relative;
}
.field__el {
  width: 100%;
  padding: 10px 20px 10px 40px;
  line-height: 1.2;
  border-radius: 3px;
  font-size: 15px;
  border: 1px solid #d3d3d3;
  color: #706f6f;
  outline: 0;
  transition: 0.3s ease all;
}
.field__el[disabled] {
  border: 1px solid #d3d3d3;
  background-color: #f8f8f8;
  color: #afafaf;
  pointer-events: none;
}
.field__el:hover {
  border-color: #b9b7b7;
}
.field__el:focus {
  border-color: #0a89ae;
}
.field__error {
  color: #dc0c23;
  font-size: 13px;
  padding-top: 5px;
  line-height: 1.3;
  padding-left: 12px;
  font-style: italic;
  position: absolute;
  right: 0;
  bottom: -17px;
  left: 0;
}
.field__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
}
.field__icon svg {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 19px;
  max-height: 17px;
}
.field--withouticon .field__el {
  padding-left: 12px;
}

.fieldrow__item--error .field__el {
  background-color: #FCDBDF;
  border: 1px solid #e14e5e;
  color: #dc0c23;
  margin-bottom: 5px;
}
.fieldrow__item--error .field__error {
  position: static;
  margin-bottom: -10px;
  padding-top: 2px;
}
@media (max-width: 767px) {
  .fieldrow__item--error .field__error {
    margin-bottom: 0;
  }
  .fieldrow--2 .fieldrow__item {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .fieldrow {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .fieldrow .fieldrow__item {
    margin-right: 20px;
  }
  .fieldrow--2 .fieldrow__item {
    width: calc(50% - 10px);
  }
  .fieldrow--2 .fieldrow__item:nth-child(2n) {
    margin-right: 0;
  }
}

.filteradvanced__main {
  padding: 70px 0 30px;
}
.filteradvanced__footer {
  background-color: #F4F4F4;
  padding: 20px 0;
  border-top: 1px solid #dbdbdb;
  text-align: center;
}
.filteradvanced__footer__btn {
  position: relative;
  display: inline-block;
}
.filteradvanced__group {
  margin-bottom: 40px;
}
.filteradvanced__group__title {
  font-size: 18px;
  color: #1f1f1f;
  margin-bottom: 15px;
}
.filteradvanced__group__list .checkbox {
  margin-bottom: 5px;
}
.filteradvanced__group__more {
  padding-left: 30px;
  margin-top: 10px;
}
.filteradvanced__group:last-child {
  margin-bottom: 0;
}
.filteradvanced__sizerow {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 5px;
}
.filteradvanced__sizerow__title {
  width: 100px;
}
.filteradvanced__sizerow__fields {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
}
.filteradvanced__sizerow__smalllabel {
  padding: 0 8px;
}
.filteradvanced__sizerow__field input {
  width: 70px;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 15px;
  border-radius: 4px;
  color: #706f6f;
  outline: 0;
}
.filteradvanced__sizerow__field--offset {
  margin-right: 10px;
}
.filteradvanced__sizerow__select {
  position: relative;
}
.filteradvanced__sizerow__select select {
  width: 70px;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 15px;
  color: #706f6f;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  outline: 0;
}
.filteradvanced__sizerow__select svg {
  position: absolute;
  top: 10px;
  right: 8px;
}
.filteradvanced__sizerow__select--offset {
  margin-right: 10px;
}
@media (max-width: 991px) {
  .filteradvanced {
    background-color: #fff;
    display: none;
    position: relative;
  }
  .filteradvanced-is-open .filteradvanced {
    display: block;
  }
  .filteradvanced__close {
    position: absolute;
    top: 25px;
    right: 10px;
  }
  .filteradvanced__col, .filteradvanced__col__rowdouble {
    margin-bottom: 20px;
  }
  .filteradvanced__footer__btn__offset {
    margin-left: 20px;
  }
}
@media (min-width: 992px) {
  .filteradvanced {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    background-color: #fff;
    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
    opacity: 0;
    pointer-events: none;
    transform: scale(0.97);
    transition: 0.3s ease all;
  }
  .filteradvanced-is-open .filteradvanced {
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
  }
  .filteradvanced__close {
    position: absolute;
    top: 25px;
    right: 40px;
  }
  .filteradvanced__cols {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .filteradvanced__col {
    width: 20%;
  }
  .filteradvanced__col--double {
    width: 40%;
  }
  .filteradvanced__col__rowdouble {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .filteradvanced__col__rowdouble > div {
    width: 50%;
  }
  .filteradvanced__footer__btn__offset {
    position: absolute;
    top: 50%;
    left: 100%;
    white-space: nowrap;
    transform: translate(40px, -50%);
  }
}

.filterbar {
  background-color: #F4F4F4;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  position: relative;
  z-index: 10000;
}
.filterbar__toggle {
  padding: 15px 0;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #dbdbdb;
}
.filterbar__toggle svg {
  margin-left: 10px;
}
.filterbar__toggle__button.is-open svg {
  transform: rotate(-180deg);
}
.filterbar__nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.filterbar__nav__item {
  margin-right: 15px;
  position: relative;
}
.filterbar__nav__item > a {
  text-decoration: none;
  color: #1f1f1f;
  padding: 10px 0;
  display: block;
  transition: 0.3s ease all;
}
.filterbar__nav__item > a span {
  position: relative;
  z-index: 5;
}
.filterbar__nav__item > a svg {
  margin-left: 6px;
}
.filterbar__nav__item.is-active > a {
  font-weight: bold;
}
.filterbar__nav__item.filterbar__nav__item--advanced:hover > a {
  color: #0a89ae;
}
.filterbar__subnav__items {
  margin-bottom: 20px;
}
.filterbar__subnav__item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 12px;
}
.filterbar__subnav__item__count {
  font-size: 15px;
}
.filterbar__subnav__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.filterbar__subnav__price__field {
  width: calc(50% - 10px);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
}
.filterbar__subnav__price__field div {
  width: 27px;
  flex-shrink: 0;
  margin-top: 3px;
}
.filterbar__subnav__price__field input {
  width: 100%;
  max-width: calc(100% - 27px);
  border-radius: 3px;
  font-size: 15px;
  border: 1px solid #d3d3d3;
  color: #706f6f;
  padding: 3px 30px 3px 5px;
  outline: 0;
}
.filterbar__subnav__price__field span {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  width: 24px;
  opacity: 0.5;
}
.filterbar__subnav__price--block {
  display: block;
  max-width: 160px;
}
.filterbar__subnav__price--block .filterbar__subnav__price__field {
  width: 100%;
  margin-bottom: 10px;
}
.filterbar__search__form {
  display: none;
  height: 19px;
}
.filterbar__search.is-open .filterbar__search__btn {
  display: none;
}
.filterbar__search.is-open .filterbar__search__form {
  display: block;
}
@media (min-width: 992px) {
  .filterbar__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .filterbar__nav ul {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .filterbar__nav__item > a {
    padding: 15px 0;
  }
  .filterbar__nav__item:not(.filterbar__nav__item--advanced) > a:before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    bottom: -1px;
    left: -10px;
    background-color: #fff;
    border: 1px solid #d7d7d7;
    border-top: none;
    border-bottom: none;
    opacity: 0;
    transition: 0.3s ease all;
  }
  .filterbar__nav__item:hover:not(.filterbar__nav__item--advanced) > a:before {
    opacity: 1;
  }
  .filterbar__nav__item:hover:not(.filterbar__nav__item--advanced) .filterbar__subnav {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  }
  .filterbar__subnav {
    position: absolute;
    top: 100%;
    left: -10px;
    margin-top: 1px;
    z-index: 20;
    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
    width: 280px;
    padding: 20px;
    border: 1px solid #d7d7d7;
    border-top: none;
    background-color: #ffffff;
    opacity: 0;
    transform: translate(0, -10px);
    pointer-events: none;
    transition: 0.3s ease all;
  }
  .filterbar__subnav__items {
    max-height: 50vh;
    overflow: auto;
    margin-right: -20px;
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .filterbar {
    border-top: none;
  }
  .filterbar__nav {
    display: none;
  }
  .filterbar-is-open .filterbar__nav {
    display: block;
  }
  .filterbar__nav__item.is-open .filterbar__nav__item__label svg {
    transform: rotate(-180deg);
  }
  .filterbar__nav__item.is-open .filterbar__subnav {
    display: block;
  }
  .filterbar__subnav {
    display: none;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 15px;
  }
  .filterbar__subnav__btn {
    max-width: 400px;
    margin: 0 auto;
  }
  .filterbar__search {
    display: none;
  }
}
@media (min-width: 1200px) {
  .filterbar__nav__item {
    margin-right: 15px;
  }
  .filterbar__nav__item:not(.filterbar__nav__item--advanced) > a:before {
    right: -10px;
    left: -10px;
  }
  .filterbar__subnav {
    left: -10px;
  }
}
@media (min-width: 1400px) {
  .filterbar__nav__item {
    margin-right: 30px;
  }
  .filterbar__nav__item:not(.filterbar__nav__item--advanced) > a:before {
    right: -20px;
    left: -20px;
  }
  .filterbar__subnav {
    left: -20px;
  }
}

.footer {
  background-color: #1f1f1f;
  padding: 30px 0;
}
.footer__copy {
  color: #fff;
  margin: 0;
  font-size: 12px;
}
.footer__nav__item {
  color: #8d8c8c;
  text-decoration: none;
  margin-right: 25px;
  position: relative;
  transition: 0.3s ease all;
}
.footer__nav__item:after {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #8d8c8c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translate(0, -50%);
}
.footer__nav__item:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  border-bottom: 1px dotted currentColor;
  opacity: 0.5;
}
.footer__nav__item:last-child:after {
  display: none;
}
.footer__social__item {
  margin-left: 10px;
  color: #706F6F;
  text-decoration: none;
  transition: 0.3s ease all;
}
.footer__social__item svg {
  height: 25px;
  width: 25px;
}
@media (max-width: 991px) {
  .footer {
    position: relative;
    padding: 20px 0;
  }
  .footer__item--first {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer__item--social {
    position: absolute;
    right: 10px;
    bottom: 16px;
  }
  .footer__nav {
    order: -1;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
    padding: 0 20px 20px;
    width: 100%;
  }
  .footer__nav__item {
    margin: 5px 25px 5px 0;
    display: inline-block;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .footer__nav__item {
    margin: 5px 10px;
  }
  .footer__nav__item:after {
    display: none;
  }
}
@media (min-width: 992px) {
  .footer__in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .footer__item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .footer__copy {
    font-size: 16px;
  }
  .footer__nav {
    margin-left: 30px;
  }
  .footer__social__item {
    margin-left: 20px;
  }
  .footer__social__item svg {
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__social__item {
    margin-left: 20px;
  }
}
@media (min-width: 992px) {
  .footer__nav__item:hover {
    color: #fff;
    text-decoration: none;
  }
  .footer__social__item:hover {
    color: #fff;
  }
}

.form__title {
  font-weight: 500;
  color: #1f1f1f;
  margin-bottom: 20px;
}
.form__row {
  margin-bottom: 15px;
}
.form__row--offset {
  margin-top: 30px;
}
.form__row--offset2x {
  margin-top: 45px;
}
.form__row--split {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
}
.form--350w {
  max-width: 350px;
  width: 100%;
}
.form--center {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .form__row--offset {
    margin-top: 30px;
  }
  .form__row--offset2x {
    margin-top: 45px;
  }
  .form__row--split {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
  }
}

.helper {
  margin-left: 5px;
  cursor: help;
  position: relative;
  pointer-events: auto;
  outline: 0;
}

.tippy-popper {
  background: #fff;
  padding: 15px;
  border: 1px solid #dbdbdb;
  color: #000;
}
.tippy-popper a {
  color: #0a89ae;
}
.tippy-popper a:hover {
  text-decoration: none;
}

.highlights__item__img {
  height: 80px;
  width: 110px;
  margin: 0 auto;
  position: relative;
}
.highlights__item__img svg {
  max-height: 75px;
  max-width: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.highlights__item__title {
  color: #1f1f1f;
  font-size: 18px;
  font-weight: 500;
}
.highlights__item__desc {
  color: #706f6f;
}
.highlights__item__desc p {
  margin: 0;
}
.highlights__item__in {
  position: relative;
  z-index: 5;
}
@media (max-width: 767px) {
  .highlights__list {
    padding: 15px 0;
  }
  .highlights__item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    text-align: left;
    margin-bottom: 30px;
  }
  .highlights__item:last-child {
    margin-bottom: 0;
  }
  .highlights__item__img {
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  .highlights__item__img {
    height: 150px;
    width: 150px;
  }
  .highlights__item__img svg {
    max-height: 85px;
    max-width: 130px;
  }
  .highlights__item__img:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 250px;
    width: 250px;
    background-color: #F4F4F4;
    border-radius: 50%;
  }
  .highlights__item__desc {
    max-width: 80%;
    margin: 0 auto;
  }
}

.historydrop {
  margin: 0 0 0 -15px;
}
.historydrop__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  position: relative;
}
.historydrop__item:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 10px;
  border-bottom: 1px solid #dbdbdb;
}
.historydrop__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.historydrop__item__img {
  margin-right: 10px;
}
.historydrop__item__title {
  font-size: 15px;
}
.historydrop__item__date {
  font-size: 14px;
  color: #706f6f;
}
.historydrop__item:hover {
  background-color: #f4f4f4;
}

.historylist__submit {
  margin: 30px 0;
}
.historylist__item {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.historylist__item__data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.historylist__item__img {
  margin-right: 10px;
}
.historylist__item__point {
  margin-right: 10px;
  position: relative;
}
.historylist__item__point:after {
  content: "";
  height: 2px;
  width: 2px;
  background-color: #706f6f;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translate(0, -50%);
  border-radius: 50%;
  opacity: 0.5;
  display: none;
}
.historylist__item__point img {
  margin-right: 7px;
  position: relative;
  bottom: 2px;
}
.historylist__item__point--light {
  color: #706f6f;
}
.historylist__item__point:last-child:after {
  display: none;
}
.historylist__item__time {
  color: #706f6f;
}
@media (max-width: 767px) {
  .historylist__item__img {
    width: 50px;
    flex-shrink: 0;
  }
  .historylist__item__time {
    font-size: 11px;
  }
}
@media (min-width: 768px) {
  .historylist__submit {
    margin: 50px 0;
  }
  .historylist__item {
    padding: 10px 20px;
  }
  .historylist__item__img {
    margin-right: 20px;
  }
  .historylist__item__point {
    margin-right: 30px;
  }
  .historylist__item__point:after {
    display: block;
  }
}

.hovernav {
  position: relative;
}
.hovernav__list {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: -12px;
  transform: translate(0, -5px);
  z-index: 50;
  box-shadow: 3px 3px 0 0 #DBDBDB;
  width: 280px;
  pointer-events: none;
  transition: 0.3s ease all;
}
.hovernav__item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  background-color: #F4F4F4;
  border-bottom: 1px solid #DBDBDB;
  color: #1f1f1f;
  text-decoration: none;
  padding: 7px 30px 7px 10px;
  font-size: 15px;
  transition: 0.3s ease all;
}
.hovernav__item:hover {
  text-decoration: none;
}
.hovernav__item__icon {
  text-align: center;
  width: 20px;
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}
.hovernav__item:last-child {
  border: none;
}
.hovernav.is-hover .hovernav__list {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: auto;
}
.hovernav.offsethover-is-active .hovernav__list {
  left: auto;
  right: -12px;
}

@media (min-width: 992px) {
  .hoverzoom {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
  .hoverzoom > img,
.hoverzoom > span {
    transition: 0.3s ease all;
  }
  .hoverzoom > span {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .hoverzoom:hover > img,
.hoverzoom:hover > span {
    transform: scale(1.05);
  }
}

@media (min-width: 992px) {
  .hoverzoom-img {
    overflow: hidden;
    display: block;
    outline: 0;
  }
  .hoverzoom-img > img {
    transition: 0.3s ease all;
  }
  .hoverzoom-img:hover > img,
.hoverzoom-img:hover > span {
    transform: scale(1.05);
  }
}

.hr {
  margin: 60px 0;
  height: 1px;
  background-color: #e2e2e2;
}
.hr.m-4-0 {
  margin: 32px 0;
}

.imageblocks__item__img {
  display: block;
  margin-bottom: 20px;
}
.imageblocks__item__title {
  font-weight: normal;
  font-size: 26px;
  margin: 0;
}
.imageblocks__item__text {
  margin-top: 20px;
  font-size: 15px;
}
.imageblocks__item__text p {
  margin: 0;
}
.imageblocks__item__text p.mt-2 {
  margin-top: 16px;
}
.imageblocks__item__table table td {
  padding-right: 10px;
}
.imageblocks__item__table table td:last-child {
  padding-right: 0;
}
.imageblocks__item--mobile {
  margin-top: 20px;
}

.inlineform__separator {
  color: #aaaaaa;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .inlineform__btns .btn {
    margin: 5px;
  }
}
@media (min-width: 768px) {
  .inlineform__btns .btn {
    margin: 0 15px;
  }
}

.interier {
  margin-top: 40px;
}
.interier__canvas {
  position: relative;
}
.interier__canvas__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: #000;
  opacity: 0;
}
.interier__canvas__item {
  z-index: 10;
}
.interier__canvas__item.is-open .coverlink {
  pointer-events: auto;
}
.interier__canvas__img {
  width: 100%;
}
.interier__canvas__img__detail {
  max-height: 70vh;
}
.interier__canvas__wrapper {
  position: relative;
  margin: 30px 0;
}
.interier__canvas__btn {
  margin-top: 20px;
}
.interier__canvas__nav {
  overflow: hidden;
}
.interier__canvas__nav__item--left {
  float: left;
  transform: rotate(180deg);
}
.interier__canvas__nav__item--right {
  float: right;
}
.interier__canvas {
  width: calc(100% - 80px);
  margin: 0 auto;
  position: relative;
}
.interier__canvas__item__pin {
  cursor: pointer;
  transition: 0.3s ease all;
}
.interier__canvas__item__more {
  position: relative;
  background-color: #f4f4f4;
  box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.5);
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  z-index: 1000;
  pointer-events: none;
}
.interier__canvas__item__more .helper,
.interier__canvas__item__more .btn {
  position: relative;
  z-index: 10;
  pointer-events: none;
}
.interier__canvas__item__more__img {
  background-color: #fff;
  text-align: center;
}
.interier__canvas__item__more__img img {
  display: block;
  margin: 0 auto;
}
.interier__canvas__item__more__left, .interier__canvas__item__more__right {
  width: calc(50% - 10px);
}
.interier__canvas__item__more__left img {
  margin-bottom: 10px;
  max-height: 200px;
  user-select: none;
}
.interier__canvas__item.is-open {
  z-index: 15;
}
.interier__canvas__item.is-open .helper,
.interier__canvas__item.is-open .btn {
  pointer-events: auto;
}
.interier__canvas__item.is-open .interier__canvas__item__more {
  opacity: 1;
  pointer-events: auto;
}
.interier__canvas__item.is-open.offsetclick-is-active .interier__canvas__item__more {
  right: auto;
  left: 90%;
}
.interier__canvas__item.is-open.offsetclicktop-is-active .interier__canvas__item__more {
  top: 0;
  bottom: auto;
}
.interier__canvas__item.is-open.offsetclickbottom-is-active .interier__canvas__item__more {
  top: auto;
  bottom: 0;
}
@media (max-width: 991px) {
  .interier__canvas {
    width: 100%;
  }
  .interier__canvas__nav {
    margin-bottom: 20px;
  }
  .interier__canvas__img {
    margin-bottom: 20px;
  }
  .interier__canvas__item {
    margin-bottom: 30px;
    position: relative;
    left: 0 !important;
    top: 0 !important;
    pointer-events: none;
  }
  .interier__canvas__item .btn {
    pointer-events: auto;
  }
  .interier__canvas__item__pin {
    display: none;
  }
  .interier__canvas__item:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .interier__canvas__nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .interier__canvas__like {
    position: absolute;
    right: 5px;
    bottom: 10px;
    z-index: 8;
  }
  .interier__canvas__pins {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .interier__canvas__item {
    position: absolute;
  }
  .interier__canvas__item__pin:hover {
    transform: translate(0, -5px);
  }
  .interier__canvas__item__more {
    position: absolute;
    top: -50px;
    right: 100%;
    width: 510px;
    max-width: 35vw;
    opacity: 0;
  }
  .interier__canvas__item__more__img {
    overflow: hidden;
  }
  .interier__canvas__item__more__img img {
    transition: 0.3s ease all;
  }
  .interier__canvas__item__more__img:hover img {
    transform: scale(1.2);
  }
}

.labelnav {
  padding: 5px 0;
}
.labelnav__text b {
  font-size: 20px;
  line-height: 1;
}
.labelnav__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.labelnav__item {
  background-color: #ebebeb;
  padding: 5px 25px 5px 10px;
  border-radius: 20px;
  line-height: 1.1;
  white-space: nowrap;
  margin: 5px 5px 5px 0;
  text-decoration: none;
  color: #202020;
  position: relative;
}
.labelnav__item svg {
  margin-left: 8px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  transition: 0.3s ease all;
}
@media (max-width: 767px) {
  .labelnav__list {
    margin: 10px 0;
    flex-wrap: wrap;
  }
  .labelnav__item {
    margin: 2px;
  }
}
@media (min-width: 768px) {
  .labelnav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .labelnav__text {
    flex-shrink: 0;
  }
  .labelnav__list {
    margin-left: 15px;
  }
  .labelnav__item {
    padding: 7px 25px 7px 12px;
    margin-right: 15px;
  }
  .labelnav__actions {
    margin-left: 20px;
    flex-shrink: 0;
  }
}
@media (min-width: 992px) {
  .labelnav__item {
    transition: 0.3s ease all;
  }
  .labelnav__item:hover {
    color: #0a89ae;
    text-decoration: none;
  }
  .labelnav__item:hover svg {
    transform: translate(0, -50%) rotate(90deg);
  }
}

.like {
  position: relative;
  white-space: nowrap;
}
.like svg {
  margin-right: 10px;
}
.like span {
  font-size: 12px;
}
.like--blue svg {
  color: #0a89ae;
}
.like--white svg {
  color: #fff;
}
@media (min-width: 768px) {
  .like svg {
    transition: 0.3s ease all;
  }
  .like:hover {
    cursor: pointer;
  }
  .like:hover svg {
    transform: scale(1.2);
  }
}

.listview__item__in {
  position: relative;
  z-index: 15;
}
.listview__item__more {
  transition: 0.5s ease opacity;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  padding: 15px;
  z-index: 15;
  opacity: 0;
  pointer-events: none;
}
.listview__item__img {
  position: relative;
}
.listview__item__img img {
  display: block;
  width: 100%;
  max-height: 250px;
}
.listview__item__carousel {
  pointer-events: none;
}
.listview__item__carousel .carousel__arrows,
.listview__item__carousel .slick-dots {
  opacity: 0;
  transition: 0.3s ease all;
}
.listview__item__labels {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
}
.listview__item__label {
  background-color: #1f1f1f;
  color: #fff;
  font-size: 12px;
  padding: 5px 9px;
  line-height: 1;
  margin-left: 10px;
}
.listview__item__actions {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 5;
  color: #fff;
}
.listview__item__footer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding-top: 5px;
}
.listview__item__title {
  margin: 0;
  color: #202020;
  font-size: 16px;
}
.listview__item__title span {
  color: #706f6f;
  font-weight: normal;
  padding-left: 5px;
  font-size: 15px;
}
.listview__item__price {
  color: #706f6f;
  font-size: 16px;
}
.listview__item--add {
  background-color: #0a89ae;
  position: relative;
  text-align: center;
}
.listview__item--add img {
  opacity: 0;
}
.listview__item--add .listview__item__add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #fff;
  font-size: 30px;
}
.listview__item--add .listview__item__add svg {
  margin-bottom: 10px;
  transition: 0.3s ease all;
}
@media (max-width: 767px) {
  .listview {
    justify-content: center;
  }
  .listview__item {
    max-width: 400px;
    margin: 0 auto 20px !important;
  }
  .listview__item__title, .listview__item__price {
    font-size: 14px;
  }
  .listview__item__labels {
    top: 5px;
    right: 5px;
  }
  .listview__item__label {
    font-size: 10px;
    margin-left: 5px;
  }
}
@media (min-width: 992px) {
  .listview__item--add:hover .listview__item__add svg {
    transform: scale(1.1) rotate(90deg);
  }
  .listview__item--morehover:before {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -5px;
    left: -10px;
    width: calc(200% + 30px);
    background-color: #F4F4F4;
    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease opacity;
  }
  .listview__item--morehover.is-hoverdelay:before {
    opacity: 1;
    pointer-events: auto;
  }
  .listview__item--morehover.is-hoverdelay .listview__item__more {
    opacity: 1;
    pointer-events: auto;
  }
  .listview__item--morehover.is-hoverdelay .listview__item__carousel {
    pointer-events: auto;
  }
  .listview__item--morehover.is-hoverdelay .listview__item__carousel .carousel__arrows,
.listview__item--morehover.is-hoverdelay .listview__item__carousel .slick-dots {
    opacity: 1;
  }
  .listview__item--morehover.is-hover:before {
    z-index: 20;
  }
  .listview__item--morehover.is-hover .listview__item__in {
    z-index: 25;
  }
  .listview__item--morehover.is-hover .listview__item__more {
    z-index: 30;
  }
  .listview__item--morehover.is-hover.offsethover-is-active:before {
    right: -10px;
    left: auto;
  }
  .listview__item--morehover.is-hover.offsethover-is-active .listview__item__more {
    left: calc(-100% - 20px);
    width: calc(100% + 20px);
  }
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease all;
}
.is-loading .loading {
  pointer-events: auto;
  opacity: 1;
}
.loading svg {
  height: 50px;
  width: 50px;
  animation: 3s spin infinite linear;
}
.loading div {
  margin-top: 10px;
}
.loading span {
  animation: 1.4s dot infinite linear;
  animation-fill-mode: both;
}
.loading span:nth-child(1) {
  margin-left: 3px;
}
.loading span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

.loginformseparator {
  position: relative;
}
.loginformseparator__text {
  color: #aaaaaa;
}
.loginformseparator__text p {
  margin: 0;
  background-color: #fff;
}
.loginformseparator__bg {
  width: 1px;
  background: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 30%, #e0e0e0 70%, #ffffff 100%);
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
}
.loginformseparator--popup945 .loginformseparator__text {
  right: 40px;
}
@media (max-width: 767px) {
  .loginformseparator__text p {
    text-align: center;
    margin-top: 35px;
  }
  .loginformseparator__bg {
    display: none;
  }
}
@media (min-width: 768px) {
  .loginformseparator__text {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 100%;
    min-height: 260px;
  }
  .loginformseparator__text p {
    position: absolute;
    top: 65px;
    right: 0;
    left: 0;
    padding: 15px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .loginformseparator__text {
    right: -11px;
  }
  .loginformseparator--popup945 .loginformseparator__text {
    right: -11px;
  }
}

.margin--top {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .mfp-arrow {
    z-index: 11000 !important;
  }
}

.mobilenav {
  position: absolute;
  top: 68px;
  right: 0;
  left: 0;
  background-color: #fff;
  padding: 25px 10px;
  display: none;
}
.mobilenav__in {
  max-width: 400px;
  margin: 0 auto;
}
.mobilenav__button {
  position: relative;
  margin-bottom: 30px;
}
.mobilenav__cols {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 20px;
}
.mobilenav__cols__item {
  position: relative;
  width: calc(50% - 5px);
}
.nav-is-open .mobilenav {
  display: block;
}

@media (max-width: 767px) {
  .mobiletable table td {
    display: block;
    width: 100%;
    padding-right: 0;
  }
}

.page {
  padding-bottom: 40px;
  overflow: hidden;
}
.page__header {
  padding: 20px 0 25px;
  position: relative;
}
.page__header__nav {
  margin-bottom: 15px;
}
.page__header__title.title {
  margin: 0;
}
.page__header__middle {
  text-align: center;
}
.page__header--offset {
  padding: 20px 0;
}
.page__header--offset2x {
  padding: 40px 0;
}
.page__header--npb {
  padding-bottom: 0;
}
@media (max-width: 991px) {
  .page__header__middle {
    margin-top: 10px;
  }
}
@media (min-width: 992px) {
  .page {
    padding-bottom: 80px;
  }
  .page__header__middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .page__header--offset {
    padding: 40px 0;
  }
  .page__header--offset2x {
    padding: 60px 0;
  }
  .page__cols {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
  }
  .page__col--75 {
    width: calc(75% - 20px);
  }
  .page__col--25 {
    width: calc(25% - 20px);
  }
}

.page404 {
  margin: 30px 0;
}
.page404__img {
  position: relative;
}
.page404__img img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.page404__content {
  padding: 20px 0;
  position: relative;
  z-index: 10;
}
.page404__claim {
  padding: 20px 0;
  background-color: #fff;
  border-radius: 20px;
}
.page404__claim h3 {
  color: #dc0b23;
  font-family: "Roboto", "Arial";
  font-size: 40px;
  line-height: 1.1;
  font-weight: 900;
  letter-spacing: -1px;
  margin: 0;
}
.page404__claim p {
  font-size: 18px;
  margin: 10px 0 0;
}
.page404__text {
  font-size: 17px;
  padding-top: 20px;
}
.page404__text p {
  margin-bottom: 30px;
}
.page404__text p:last-child {
  margin-bottom: 0;
}
.page404__link {
  color: #979797;
  font-size: 22px;
}
@media (max-width: 767px) {
  .page404__img {
    height: 200px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .page404 {
    margin: 60px 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .page404__img {
    width: 50%;
  }
  .page404__content {
    width: 50%;
    padding: 40px 0;
  }
  .page404__claim {
    margin-left: -180px;
    width: calc(100% + 180px);
    padding: 20px 35px 30px;
  }
  .page404__claim h3 {
    font-size: 50px;
  }
  .page404__claim p {
    font-size: 20px;
  }
  .page404__text {
    padding-left: 40px;
  }
}
@media (min-width: 992px) {
  .page404__claim h3 {
    font-size: 56px;
  }
}

.popup {
  width: 96%;
  max-width: 600px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  margin: 10px auto;
  padding: 30px 20px 10px;
  position: relative;
}
.popup__close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #0a89ae;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: 0.3s ease all;
}
.popup__close span {
  font-size: 20px;
  margin-right: 5px;
}
.popup__close div {
  text-decoration: underline;
}
.popup__footer {
  background-color: #F4F4F4;
  border-top: 1px solid #dbdbdb;
  margin: 0 -40px -10px;
  padding: 20px;
}
.popup__footer__claim {
  margin: 0;
}
.popup__footer__claim a {
  display: inline-block;
  margin: 0 5px;
}
.popup--555 {
  max-width: 555px;
}
.popup--820 {
  max-width: 820px;
}
.popup--945 {
  max-width: 945px;
}
@media (max-width: 767px) {
  .popup__content .cols {
    max-width: 400px;
    margin: 0 auto;
  }
  .popup__content .cols .cols__item:first-child {
    max-width: 285px;
    margin: 0 auto;
  }
  .popup__content .cols .cols__item:first-child .form__title {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .popup {
    padding: 30px 25px;
  }
  .popup__footer {
    margin: 0 -40px -30px;
    padding: 30px;
  }
}
@media (min-width: 992px) {
  .popup {
    padding: 30px 40px;
  }
  .popup__close:hover {
    color: #dc0c23;
  }
}

.quote__item {
  background-color: #e5e5e5;
  padding: 10px;
}
.quote__item__text {
  margin: 10px 0;
}
.quote__item__text p {
  margin: 0;
}
.quote__item__profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
}
.quote__item__profile img {
  border-radius: 50%;
  margin-right: 15px;
}
.quote__item__profile p {
  margin: 0;
  color: #706f6f;
}
.quote__item--alt {
  background-color: #F4F4F4;
}
@media (max-width: 767px) {
  .quote__item .icon-quote {
    max-width: 30px;
    height: 20px;
  }
  .quote__item__profile img {
    display: none;
  }
}
@media (min-width: 768px) {
  .quote__item {
    padding: 20px 40px;
  }
}

@media (max-width: 767px) {
  .quotecarousel .quote__item {
    margin: 0 25px;
  }
}
@media (min-width: 768px) {
  .quotecarousel .quote__item {
    margin-right: 20px;
  }
}

.rowform {
  max-width: 850px;
  margin: 30px auto;
}
.rowform__row {
  margin-bottom: 15px;
  display: block;
}
.rowform__label__value {
  font-size: 15px;
  color: #1f1f1f;
  font-weight: bold;
  user-select: none;
}
.rowform__box {
  background-color: #F4F4F4;
  border: 1px solid #dbdbdb;
  padding: 20px 0;
  margin: 30px 0;
}
.rowform__box--inline {
  padding-top: 0;
}
.rowform__title {
  font-size: 22px;
  color: #1f1f1f;
  margin-bottom: 20px;
}
.rowform__row--error .field__el {
  background-color: #FCDBDF;
  border: 1px solid #e14e5e;
  color: #dc0c23;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .rowform__label {
    padding-bottom: 2px;
  }
}
@media (min-width: 768px) {
  .rowform {
    margin: 60px auto;
  }
  .rowform__row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 25px;
  }
  .rowform__row--long {
    align-items: flex-start;
  }
  .rowform__row--long .rowform__label {
    margin-top: 1px;
  }
  .rowform__row--long .rowform__field__toggle {
    margin-top: 10px;
  }
  .rowform__label {
    width: 30%;
  }
  .rowform__field {
    width: 70%;
  }
  .rowform__box {
    padding: 50px 0 40px;
    margin: 50px 0;
  }
  .rowform__box--inline {
    padding-top: 20px;
    margin-top: 30px;
  }
  .rowform__title {
    margin-bottom: 40px;
  }
}

.rowgallery__row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 5px;
}
.rowgallery__row div {
  margin-left: 5px;
  max-width: 32%;
}
.rowgallery__row div:first-child {
  margin-left: 0;
}

.search {
  border-bottom: 1px solid #d3d3d3;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  white-space: nowrap;
}
.search__input, .search__btn {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: 0;
}
.search__input {
  width: 100%;
  max-width: 200px;
}
.search__btn {
  color: #0a89ae;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.search__btn svg {
  margin-right: 6px;
  position: relative;
  bottom: 2px;
}
@media (max-width: 991px) {
  .search__btn span {
    display: none;
  }
}

.section__btn {
  margin-top: 30px;
}
.section__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.section--bg {
  background-color: #F4F4F4;
}
.section--bgalt {
  background-color: #e5e5e5;
}
@media (max-width: 767px) {
  .section--margin {
    margin: 15px 0;
  }
  .section--margin2x {
    margin: 30px 0;
  }
  .section--padding {
    padding: 15px 0;
  }
  .section--padding2x {
    padding: 30px 0;
  }
}
@media (min-width: 768px) {
  .section--margin {
    margin: 30px 0;
  }
  .section--margin2x {
    margin: 60px 0;
  }
  .section--padding {
    padding: 30px 0;
  }
  .section--padding2x {
    padding: 60px 0;
  }
}

.series__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.series__perex__attr {
  line-height: 1.6;
  margin: 15px 0;
}
.series__perex__attr span {
  color: #929292;
}
.series__group {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .series__carousel {
    margin: 0 -10px;
  }
}
@media (min-width: 768px) {
  .series__group {
    margin-bottom: 50px;
  }
}

.seriescarousel__item {
  margin-right: 5px;
  position: relative;
}
.seriescarousel__item__like {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff;
}

.serieslist {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
.serieslist__item {
  position: relative;
}
.serieslist__item__in {
  position: relative;
}
.serieslist__item__img {
  margin-bottom: 10px;
  height: 200px;
  position: relative;
  background-color: #fff;
}
.serieslist__item__img .hoverzoom {
  height: 100%;
}
.serieslist__item__img .btn {
  transition: 0.5s ease opacity;
  position: absolute;
  right: 50%;
  bottom: 10px;
  transform: translate(50%, 0);
  background-color: #fff;
  z-index: 5;
  opacity: 0;
}
.serieslist__item__img .btn:hover {
  background-color: #0a89ae;
}
.serieslist__item__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  width: auto;
}
.serieslist__item__more {
  transition: 0.5s ease opacity;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  padding: 15px;
  z-index: 14;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 767px) {
  .serieslist__item {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .serieslist__item:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .serieslist__item .attrs__btn {
    position: relative;
    z-index: 10;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .serieslist__item {
    width: calc(33.3333333333% - 15px);
    margin-right: 22px;
    margin-bottom: 22px;
  }
  .serieslist__item:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .serieslist__item--morehover:before {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -25px;
    left: -10px;
    width: calc(200% + 35px);
    background-color: #F4F4F4;
    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease opacity;
  }
  .serieslist__item--morehover.is-hoverdelay:before, .serieslist__item--morehover.is-tippy-hover:before {
    opacity: 1;
    pointer-events: auto;
  }
  .serieslist__item--morehover.is-hoverdelay .serieslist__item__img .btn, .serieslist__item--morehover.is-tippy-hover .serieslist__item__img .btn {
    opacity: 1;
  }
  .serieslist__item--morehover.is-hoverdelay .serieslist__item__more, .serieslist__item--morehover.is-tippy-hover .serieslist__item__more {
    opacity: 1;
    pointer-events: auto;
  }
  .serieslist__item--morehover.is-hoverdelay .serieslist__item__carousel, .serieslist__item--morehover.is-tippy-hover .serieslist__item__carousel {
    pointer-events: auto;
  }
  .serieslist__item--morehover.is-hoverdelay .serieslist__item__carousel .carousel__arrows,
.serieslist__item--morehover.is-hoverdelay .serieslist__item__carousel .slick-dots, .serieslist__item--morehover.is-tippy-hover .serieslist__item__carousel .carousel__arrows,
.serieslist__item--morehover.is-tippy-hover .serieslist__item__carousel .slick-dots {
    opacity: 1;
  }
  .serieslist__item--morehover.is-hover .serieslist__item__img .btn, .serieslist__item--morehover.is-tippy-hover .serieslist__item__img .btn {
    display: block;
  }
  .serieslist__item--morehover.is-hover .serieslist__item__in, .serieslist__item--morehover.is-tippy-hover .serieslist__item__in {
    z-index: 15;
  }
  .serieslist__item--morehover.is-hover.offsethover-is-active:before, .serieslist__item--morehover.is-tippy-hover.offsethover-is-active:before {
    right: -10px;
    left: auto;
  }
  .serieslist__item--morehover.is-hover.offsethover-is-active .serieslist__item__more, .serieslist__item--morehover.is-tippy-hover.offsethover-is-active .serieslist__item__more {
    left: calc(-100% - 20px);
    width: calc(100% + 20px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .serieslist__item {
    width: calc(25% - 15px);
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .serieslist__item:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1199px) and (max-width: 1500px) {
  .serieslist__item {
    width: calc(20% - 20px);
    margin-right: 25px;
    margin-bottom: 25px;
  }
  .serieslist__item:nth-child(5n) {
    margin-right: 0;
  }
}
@media (min-width: 1500px) {
  .serieslist__item {
    width: calc(16.6666666667% - 40px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .serieslist__item__more {
    width: calc(100% + 80px);
  }
  .serieslist__item--morehover:before {
    width: calc(200% + 80px);
  }
  .serieslist__item:nth-child(6n) {
    margin-right: 0;
  }
  .serieslist__item--morehover.is-hover.offsethover-is-active:before {
    right: -10px;
    left: auto;
  }
  .serieslist__item--morehover.is-hover.offsethover-is-active .serieslist__item__more {
    left: calc(-100% - 70px);
    width: calc(100% + 80px);
  }
}

.share__item {
  display: block;
  margin-bottom: 10px;
  transition: 0.3s ease all;
}
@media (max-width: 767px) {
  .share__item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .share__item:hover {
    transform: scale(1.1);
  }
}

.showroom__item {
  position: relative;
  overflow: hidden;
}
.showroom__item__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: rgba(31, 31, 31, 0.85);
  color: #fff;
  width: auto;
  font-size: 18px;
  padding: 14px 24px;
}
.showroom__item__img {
  transition: 0.3s ease all;
}
@media (min-width: 768px) {
  .showroom__label {
    width: auto;
    font-size: 26px;
    padding: 21px 49px;
  }
}
@media (min-width: 992px) {
  .showroom__item:hover .showroom__item__img {
    transform: scale(1.05);
  }
}

.single__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.single__header__title.title {
  margin: 0;
}
.single__header__title.title span {
  color: #706f6f;
  font-size: 18px;
  padding-left: 12px;
  position: relative;
  bottom: 2px;
}
.single__header__price {
  margin: 10px 0;
}
.single__header__item {
  margin-right: 15px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.single__header__item:after {
  content: "";
  height: 2px;
  width: 2px;
  background-color: #706f6f;
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translate(0, -50%);
  border-radius: 50%;
  opacity: 0.5;
}
.single__header__item img {
  margin-right: 7px;
  position: relative;
  bottom: 2px;
}
.single__header__item__eye {
  margin-left: 10px;
  margin-top: 2px;
}
.single__header__item--flag {
  white-space: nowrap;
}
.single__header__item--light {
  color: #706f6f;
}
.single__header__item:first-child {
  flex-shrink: 0;
}
.single__header__item:last-child:after {
  display: none;
}
.single__header--offset {
  margin-bottom: 30px;
}
.single__sidebar {
  margin-top: 50px;
}
.single__sidebar__btn {
  margin: 10px 0;
}
.single__sidebar__bg {
  background-color: #F4F4F4;
  box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
  padding: 25px 30px;
}
.single__sidebar__gallery {
  margin-top: 50px;
}
.single__sidebar__gallery__title.title {
  font-size: 18px;
}
.single__sidebar__gallery__title.title span {
  color: #706f6f;
  font-size: 16px;
  padding-left: 7px;
}
.single__sidebar__gallery__list {
  margin-top: 15px;
}
.single__sidebar__gallery__item {
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
}
.single__sidebar__gallery__item .like {
  position: absolute;
  right: 0;
  bottom: 10px;
}
@media (min-width: 992px) {
  .series__header .single__header__item:not(:first-child) {
    top: 2px;
  }
  .single__sidebar__gallery__item img {
    transition: 0.3s ease all;
  }
  .single__sidebar__gallery__item:hover img {
    transform: scale(1.05);
  }
}

.site {
  overflow: hidden;
}
.site__bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  opacity: 0;
  transition: 0.3s ease all;
  pointer-events: none;
}
.site__bg.is-active {
  z-index: 10005;
  pointer-events: auto;
}
.filteradvanced-is-open .site__bg, .nav-is-open .site__bg {
  opacity: 0.9;
  pointer-events: auto;
}
.nav-is-open .site__bg {
  background-color: #000;
}

.sizeformats {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
}
.sizeformats__item {
  margin-right: 30px;
}
.sizeformats__item__object {
  background-color: #ebebeb;
}
.sizeformats__item__desc {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .sizeformats__item {
    margin-bottom: 15px;
  }
}

.slick-slider > div,
.slick-slider > article {
  display: none;
}
.slick-slider > div:first-child,
.slick-slider > article:first-child {
  display: block;
}
.slick-slider.slick-initialized > div,
.slick-slider.slick-initialized > article {
  display: block;
}

.sticky {
  padding: 8px 0;
  position: relative;
  z-index: 10005;
  background-color: #fff;
}
.sticky__in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 767px) {
  .sticky__in {
    display: block;
  }
}
.sticky__in__item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 767px) {
  .sticky__in__item {
    display: block;
  }
}
.sticky__phone, .sticky__mail {
  margin-right: 24px;
}
.sticky .icon-phone,
.sticky .icon-mail {
  width: 25px;
  height: 25px;
}
.sticky .selectric-wrapper {
  margin-right: 30px;
}
.sticky__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 40px;
}
.sticky__logo__claim {
  color: #706f6f;
  font-size: 16px;
  margin-left: 20px;
}
.sticky__button {
  margin-right: 20px;
}
.sticky__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.sticky__actions__item {
  margin-left: 10px;
}
.sticky__actions__item:first-item {
  margin-left: 0;
}
.sticky__actions__btn {
  position: relative;
  cursor: pointer;
}
.sticky__actions__btn svg {
  margin-right: 15px;
}
.sticky__actions__btn span {
  background-color: #dc0c23;
  color: #fff;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  border-radius: 15px;
  box-shadow: 0 0 0 3px #fff;
  position: absolute;
  top: -4px;
  right: 0;
}
@media (max-width: 991px) {
  .sticky {
    border-bottom: 1px solid #DBDBDB;
  }
  .sticky__nav {
    display: none;
  }
}
@media (min-width: 992px) {
  .sticky {
    padding: 13px 0;
  }
  .is-page404 .sticky {
    border-bottom: 1px solid #DBDBDB;
  }
}
@media (min-width: 1199px) and (max-width: 1300px) {
  .sticky__button .btn--dropdown {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .sticky__button {
    display: none;
  }
}
@media (max-width: 1599px) {
  .sticky__logo__claim {
    display: none;
  }
}

@media (max-width: 991px) {
  .stickynav {
    margin: 20px 0 0;
  }
  .stickynav__item {
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
  }
}
@media (min-width: 992px) {
  .stickynav {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .stickynav__item {
    text-decoration: none;
    text-transform: uppercase;
    color: #1f1f1f;
    margin-right: 20px;
    position: relative;
    transition: 0.3s ease all;
  }
  .stickynav__item:after {
    content: "";
    height: 4px;
    width: 4px;
    background-color: #d7d7d7;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translate(0, -50%);
  }
  .stickynav__item:last-child {
    margin-right: 0;
  }
  .stickynav__item:last-child:after {
    display: none;
  }
  .stickynav__item:hover {
    color: #0a89ae;
    text-decoration: none;
  }
}

.tabnav {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}
.tabnav__in {
  border: 1px solid #dbdbdb;
  border-left: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}
.tabnav__item {
  text-decoration: none;
  color: #0a89ae;
  padding: 10px 20px;
  line-height: 1.2;
  border-left: 1px solid #dbdbdb;
  position: relative;
}
.tabnav__item.is-active {
  color: #1f1f1f;
  background-color: #dbdbdb;
}
.tabnav__item.is-active:before {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #dbdbdb;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
}
@media (min-width: 768px) {
  .tabnav__item {
    padding: 11px 25px;
  }
}
@media (min-width: 992px) {
  .tabnav__item:hover {
    text-decoration: none;
  }
}

.test {
  position: absolute;
}

.title {
  display: block;
}

.tip {
  border: 1px solid #f0e8c4;
  background-color: #f9f6e7;
  padding: 15px 45px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  max-width: 285px;
  margin: 15px 0;
}
.tip svg {
  position: absolute;
  top: 15px;
  left: 10px;
}
.tip__wrapper {
  margin-bottom: 40px;
}
.tip__text {
  color: #706f6f;
}
.tip--full {
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}
.tip--yellow svg {
  color: #C4A92C;
}
.tip--green {
  background-color: #e4f9e8;
  border: 1px solid #b4e2bd;
}
.tip--green svg {
  color: #48ad5b;
}
.tip--red {
  background-color: #FCDBDF;
  border: 1px solid #e14e5e;
  color: #dc0c23;
}
.tip--red svg {
  color: #D9152B;
}

.title {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.title--article {
  font-size: 16px;
  color: #0a89ae;
  line-height: 1.2;
  font-weight: bold;
}
.title--section {
  color: #1f1f1f;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 15px;
}
.title--group {
  font-size: 22px;
  color: #1f1f1f;
  margin-bottom: 15px;
}
.title--group span {
  color: #706f6f;
  font-size: 18px;
  padding-left: 6px;
}
.title__count {
  font-size: 20px;
  color: #706f6f;
  display: inline-block;
  margin-left: 7px;
}
.title__nav {
  display: inline-block;
  font-size: 20px;
}
.title__nav__separator {
  display: inline-block;
  padding: 0 7px;
  color: #706f6f;
}
.title__actions {
  display: inline-block;
  margin-left: 20px;
}
@media (min-width: 768px) {
  .title--section {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

.togglenav {
  height: 50px;
  width: 50px;
  background-color: #F4F4F4;
  position: relative;
  border: 1px solid #DBDBDB;
}
.togglenav svg {
  max-width: 34px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.togglenav svg.icon-close-dark {
  display: none;
  height: auto;
  max-width: 23px;
}

.nav-is-open .togglenav svg.icon-togglenav {
  display: none;
}
.nav-is-open .togglenav svg.icon-close-dark {
  display: block;
}

.wishlistdrop {
  margin-bottom: 20px;
}
.wishlistdrop__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  position: relative;
  transition: 0.3s ease all;
}
.wishlistdrop__item > .coverlink {
  right: 80px;
}
.wishlistdrop__item:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #dbdbdb;
}
.wishlistdrop__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.wishlistdrop__item__img {
  margin-right: 10px;
}
.wishlistdrop__item__title {
  font-size: 15px;
}
.wishlistdrop__item__title span {
  color: #706f6f;
  font-size: 14px;
  padding-left: 3px;
}
.wishlistdrop__item__actions {
  position: relative;
}
.wishlistdrop__item:hover {
  background-color: #f4f4f4;
}

.wishlistgrid__item {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  margin-bottom: 25px;
}