.tip {
    border: 1px solid #f0e8c4;
    background-color: #f9f6e7;
    padding: 15px 45px;
    position: relative;
    border-radius: 5px;
    width: 100%;
    max-width: 285px;
    margin: 15px 0;

    svg {
        @include absolute(15px null null 10px);
    }

    &__wrapper {
        margin-bottom: 40px;
    }

    &__text {
        color: $c-text-light;
    }

    &--full {
        max-width: 790px;
        margin-left: auto;
        margin-right: auto;
    }

    &--yellow {
        svg {
            color: #C4A92C;
        }
    }

    &--green {
        background-color: #e4f9e8;
        border: 1px solid #b4e2bd;

        svg {
            color: #48ad5b;
        }
    }

    &--red {
        background-color: #FCDBDF;
        border: 1px solid #e14e5e;
        color: #dc0c23;

        svg {
            color: #D9152B;
        }
    }
}
