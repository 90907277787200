.filteradvanced {
    &__close {
        //

    }

    &__main {
        padding: 70px 0 30px;
    }

    &__footer {
        background-color: $c-bg;
        padding: 20px 0;
        border-top: 1px solid #dbdbdb;
        text-align: center;

        &__btn {
            position: relative;
            display: inline-block;
        }
    }

    &__group {
        margin-bottom: 40px;

        &__title {
            font-size: 18px;
            color: $c-text-dark;
            margin-bottom: 15px;
        }

        &__list {
            .checkbox {
                margin-bottom: 5px;
            }
        }

        &__more {
            padding-left: 30px;
            margin-top: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__cols {
        //

    }

    &__col {
        //

    }

    &__col__rowdouble {
        //

    }

    &__sizerow {
        @include flex;

        margin-bottom: 5px;

        &__title {
            width: 100px;
        }

        &__fields {
            @include flex;
        }

        &__smalllabel {
            padding: 0 8px;
        }

        &__field {
            input {
                width: 70px;
                border: 1px solid #d3d3d3;
                background-color: #ffffff;
                padding: 0 10px;
                font-size: 15px;
                border-radius: 4px;
                color: $c-text-light;
                outline: 0;
            }

            &--offset {
                margin-right: 10px;
            }
        }

        &__select {
            position: relative;

            select {
                width: 70px;
                border: 1px solid #d3d3d3;
                background-color: #ffffff;
                padding: 0 10px;
                font-size: 15px;
                color: $c-text-light;
                -webkit-appearance: none;
                appearance: none;
                -moz-appearance: none;
                outline: 0;
            }

            svg {
                @include absolute(10px 8px null null);
            }

            &--offset {
                margin-right: 10px;
            }
        }
    }

    @include media($break992M) {
        background-color: #fff;
        display: none;
        position: relative;

        .filteradvanced-is-open & {
            display: block;
        }

        &__close {
            @include absolute(25px 10px null null);
        }

        &__col,
        &__col__rowdouble {
            margin-bottom: 20px;
        }

        &__footer {
            &__btn {
                &__offset {
                    margin-left: 20px;
                }
            }
        }
    }


    @include media($break992) {
        @include absolute(0 0 null 0);

        z-index: 15;
        background-color: #fff;
        box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
        opacity: 0;
        pointer-events: none;
        transform: scale(0.97);

        @include ts;

        .filteradvanced-is-open & {
            pointer-events: auto;
            transform: scale(1);
            opacity: 1;
        }

        &__close {
            @include absolute(25px 40px null null);
        }

        &__cols {
            @include flex;
        }

        &__col {
            width: 20%;

            &--double {
                width: 40%;
            }
        }

        &__col__rowdouble {
            @include flex;

            margin-bottom: 30px;

            > div {
                width: 50%;
            }
        }

        &__footer {
            &__btn {
                &__offset {
                    @include absolute(50% null null 100%);

                    white-space: nowrap;
                    transform: translate(40px, -50%);
                }
            }
        }
    }
}
