.animate {
    @include media($break992) {
        &__item {
            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.2s;
            }

            &:nth-child(4) {
                transition-delay: 0.3s;
            }

            &:nth-child(5) {
                transition-delay: 0.4s;
            }

            &:nth-child(6) {
                transition-delay: 0.5s;
            }

            &:nth-child(7) {
                transition-delay: 0.6s;
            }

            &:nth-child(8) {
                transition-delay: 0.7s;
            }

            &:nth-child(9) {
                transition-delay: 0.8s;
            }
        }

        &--2row {
            &:nth-child(even) {
                transition-delay: 0.2s;
            }
        }

        &--fall,
        &--fall-long {
            opacity: 0;
            transform: translate(0, -10px);
            transition: 0.5s ease transform, 0.5s ease opacity;

            &.is-visible,
            .is-visible & {
                transform: translate(0);
                opacity: 1;
            }
        }

        &--fall-long {
            transform: translate(0, -50px);
        }

        &--slidein {
            opacity: 0;
            transform: translate(-5%, 0);
            transition: 0.5s ease transform, 0.5s ease opacity;

            &.is-visible,
            .is-visible & {
                transform: translate(0);
                opacity: 1;
            }
        }

        &--pop {
            opacity: 0;
            transform: scale(1.2);
            transition: 0.5s ease transform, 0.5s ease opacity;

            &.is-visible,
            .is-visible & {
                transform: scale(1);
                opacity: 1;
            }
        }

        &--fade {
            opacity: 0;
            transition: 0.3s ease transform, 0.3s ease opacity;

            &.is-visible,
            .is-visible & {
                opacity: 1;
            }
        }
    }
}
