.togglenav {
    @include size(50px);

    background-color: $c-bg;
    position: relative;
    border: 1px solid #DBDBDB;

    svg {
        max-width: 34px;
        width: 100%;

        @include absolute-center;

        &.icon-togglenav {
            //

        }

        &.icon-close-dark {
            display: none;
            height: auto;
            max-width: 23px;
        }
    }
}

.nav-is-open {
    .togglenav {
        svg {
            &.icon-togglenav {
                display: none;
            }

            &.icon-close-dark {
                display: block;
            }
        }
    }
}
