.site {
    overflow: hidden;

    &__bg {
        @include fixed(0);

        background-color: #fff;
        z-index: 10;
        opacity: 0;

        @include ts;

        pointer-events: none;

        &.is-active {
            z-index: 10005;
            pointer-events: auto;
        }

        .filteradvanced-is-open &,
        .nav-is-open & {
            opacity: 0.9;
            pointer-events: auto;
        }

        .nav-is-open & {
            background-color: #000;
        }
    }
}
