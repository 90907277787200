.filterbar {
    background-color: $c-bg;
    border-top: $b-primary;
    border-bottom: $b-primary;
    position: relative;
    z-index: 10000;

    &__toggle {
        padding: 15px 0;
        font-size: 14px;
        font-weight: bold;
        border-bottom: $b-primary;

        svg {
            margin-left: 10px;
        }

        &__button {
            &.is-open {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &__nav {
        ul {
            @include reset-ul;
        }

        &__item {
            margin-right: 15px;
            position: relative;

            > a {
                text-decoration: none;
                color: $c-text-dark;
                padding: 10px 0;
                display: block;

                @include ts;

                span {
                    position: relative;
                    z-index: 5;
                }

                svg {
                    margin-left: 6px;
                }
            }

            &.is-active {
                > a {
                    font-weight: bold;
                }
            }

            &.filterbar__nav__item--advanced:hover {
                > a {
                    color: $c-main;
                }
            }
        }
    }

    &__subnav {
        &__items {
            margin-bottom: 20px;
        }

        &__item {
            @include flex(space-between);

            margin-bottom: 12px;

            &__count {
                font-size: 15px;
            }
        }

        &__price {
            @include flex(space-between, center);

            &__field {
                width: calc(50% - 10px);
                position: relative;

                @include flex;

                div {
                    width: 27px;
                    flex-shrink: 0;
                    margin-top: 3px;
                }

                input {
                    width: 100%;
                    max-width: calc(100% - 27px);
                    border-radius: 3px;
                    font-size: 15px;
                    border: 1px solid #d3d3d3;
                    color: $c-text-light;
                    padding: 3px 30px 3px 5px;
                    outline: 0;
                }

                span {
                    @include absolute(4px 0 0 null);

                    width: 24px;
                    opacity: 0.5;
                }
            }

            &--block {
                display: block;
                max-width: 160px;

                .filterbar__subnav__price__field {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__search {
        &__form {
            display: none;
            height: 19px;
        }

        &.is-open {
            .filterbar__search {
                &__btn {
                    display: none;
                }

                &__form {
                    display: block;
                }
            }
        }
    }

    @include media($break992) {
        &__nav {
            @include flex(space-between, center);

            ul {
                @include flex;
            }

            &__item {
                > a {
                    padding: 15px 0;
                }

                &:not(.filterbar__nav__item--advanced) {
                    > a {
                        &:before {
                            content: "";

                            @include absolute(0 -10px -1px);

                            background-color: #fff;
                            border: 1px solid #d7d7d7;
                            border-top: none;
                            border-bottom: none;
                            opacity: 0;

                            @include ts;
                        }
                    }
                }

                &:hover:not(.filterbar__nav__item--advanced) {
                    > a {
                        &:before {
                            opacity: 1;
                        }
                    }

                    .filterbar__subnav {
                        opacity: 1;
                        transform: translate(0, 0);
                        pointer-events: auto;
                    }
                }
            }
        }

        &__subnav {
            @include absolute(100% null null -10px);

            margin-top: 1px;
            z-index: 20;
            box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
            width: 280px;
            padding: 20px;
            border: 1px solid #d7d7d7;
            border-top: none;
            background-color: #ffffff;
            opacity: 0;
            transform: translate(0, -10px);
            pointer-events: none;

            @include ts;

            &__items {
                max-height: 50vh;
                overflow: auto;
                margin-right: -20px;
                padding-right: 20px;
            }
        }
    }


    @include media($break992M) {
        border-top: none;

        &__nav {
            display: none;

            .filterbar-is-open & {
                display: block;
            }

            &__item.is-open {
                .filterbar__nav__item__label {
                    svg {
                        transform: rotate(-180deg);
                    }
                }

                .filterbar__subnav {
                    display: block;
                }
            }
        }

        &__subnav {
            display: none;
            padding: 15px;
            background-color: #fff;
            margin-bottom: 15px;

            &__btn {
                max-width: 400px;
                margin: 0 auto;
            }
        }

        &__search {
            display: none;
        }
    }


    @include media($break1200) {
        &__nav {
            &__item {
                margin-right: 15px;

                &:not(.filterbar__nav__item--advanced) {
                    > a {
                        &:before {
                            right: -10px;
                            left: -10px;
                        }
                    }
                }
            }
        }

        &__subnav {
            left: -10px;
        }
    }


    @include media($break1400) {
        &__nav {
            &__item {
                margin-right: 30px;

                &:not(.filterbar__nav__item--advanced) {
                    > a {
                        &:before {
                            right: -20px;
                            left: -20px;
                        }
                    }
                }
            }
        }

        &__subnav {
            left: -20px;
        }
    }
}
