.hoverzoom {
    @include media($break992) {
        overflow: hidden;
        display: inline-block;
        vertical-align: top;

        // height: 100%
        width: 100%;

        > img,
        > span {
            @include ts;
        }

        > span {
            display: inline-block;
            width: 100%;
            height: 100%;
        }

        &:hover {
            > img,
            > span {
                transform: scale(1.05);
            }
        }
    }
}

.hoverzoom-img {
    @include media($break992) {
        overflow: hidden;
        display: block;
        outline: 0;

        > img {
            @include ts;
        }

        &:hover {
            > img,
            > span {
                transform: scale(1.05);
            }
        }
    }
}
