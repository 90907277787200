// Global
$radius: 2px;
$base-font-size: 16px;
$base-line-height: 1.4;
$pixelRatio: 8;

// Fonts
$f-primary: "Roboto", "Arial";
$f-secondary: "Sans";

// Colors
$c-main: #0a89ae;
$c-main-hover: #06a2d0;
$c-secondary: #dc0c23;

$c-text: #202020;
$c-text-light: #706f6f;
$c-text-dark: #1f1f1f;

$c-bg: #F4F4F4;
$c-bg-dark: #1f1f1f;

$section-spacing: 30px;

$b-primary: 1px solid #dbdbdb;

// Breakpoints
$break320: "(min-width: 320px)";
$break480: "(min-width: 480px)";
$break768: "(min-width: 768px)";
$break992: "(min-width: 992px)";
$break1200: "(min-width: 1200px)";
$break1300: "(min-width: 1300px)";
$break1400: "(min-width: 1400px)";
$break1500: "(min-width: 1500px)";
$break1600: "(min-width: 1600px)";
$break2560: "(min-width: 2560px)";

$break320M: "(max-width: 319px)";
$break480M: "(max-width: 479px)";
$break768M: "(max-width: 767px)";
$break992M: "(max-width: 991px)";
$break1200M: "(max-width: 1199px)";
$break1300M: "(max-width: 1299px)";
$break1400M: "(max-width: 1399px)";
$break1500M: "(max-width: 1499px)";
$break1600M: "(max-width: 1599px)";
$break2560M: "(max-width: 2559px)";

$break768-992M: "(min-width: 768px) and (max-width: 991px)";
$break992-1200M: "(min-width: 992px) and (max-width: 1199px)";
$break1200-1300M: "(min-width: 1199px) and (max-width: 1300px)";
$break1200-1400M: "(min-width: 1199px) and (max-width: 1400px)";
$break1200-1500M: "(min-width: 1199px) and (max-width: 1500px)";

$landscape: "(orientation: landscape)";
$portrait: "(orientation: portrait)";
