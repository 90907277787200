@keyframes spin {
    0% {
        transform: rotate(0deg) translateZ(0);
    }

    100% {
        transform: rotate(360deg) translateZ(0);
    }
}

@keyframes dot {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}
