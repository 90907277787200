.single {
    &__header {
        @include flex(flex-start, center);

        flex-wrap: wrap;

        &__title.title {
            margin: 0;

            span {
                color: $c-text-light;
                font-size: 18px;
                padding-left: 12px;
                position: relative;
                bottom: 2px;
            }
        }

        &__price {
            margin: 10px 0;
        }

        &__item {
            margin-right: 15px;
            position: relative;

            @include flex(flex-start, center);

            &:after {
                content: "";

                @include size(2px);

                background-color: $c-text-light;

                @include absolute(50% -8px null null);

                transform: translate(0, -50%);
                border-radius: 50%;
                opacity: 0.5;
            }

            img {
                margin-right: 7px;

                @include relative(null null 2px null);
            }

            &__eye {
                margin-left: 10px;
                margin-top: 2px;
            }

            &--flag {
                white-space: nowrap;
            }

            &--light {
                color: $c-text-light;
            }

            &:first-child {
                flex-shrink: 0;
            }

            &:last-child:after {
                display: none;
            }
        }

        &--offset {
            margin-bottom: 30px;
        }
    }

    &__sidebar {
        margin-top: 50px;

        &__btn {
            margin: 10px 0;
        }

        &__bg {
            background-color: $c-bg;
            box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
            padding: 25px 30px;
        }

        &__gallery {
            margin-top: 50px;

            &__title.title {
                font-size: 18px;

                span {
                    color: $c-text-light;
                    font-size: 16px;
                    padding-left: 7px;
                }
            }

            &__list {
                margin-top: 15px;
            }

            &__item {
                position: relative;
                margin-bottom: 8px;
                overflow: hidden;

                .like {
                    @include absolute(null 0 10px null);
                }
            }
        }
    }

    @include media($break992) {
        &__header__item:not(:first-child) {
            .series__header & {
                top: 2px;
            }
        }

        &__sidebar {
            &__gallery {
                &__item {
                    img {
                        @include ts;
                    }

                    &:hover {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}
