.tabnav {
    @include flex(center);

    &__in {
        border: 1px solid #dbdbdb;
        border-left: 0;

        @include flex(center);
    }

    &__item {
        text-decoration: none;
        color: $c-main;
        padding: 10px 20px;
        line-height: 1.2;
        border-left: 1px solid #dbdbdb;
        position: relative;

        &.is-active {
            color: $c-text-dark;
            background-color: #dbdbdb;

            &:before {
                content: "";

                @include size(10px);

                background-color: #dbdbdb;

                @include absolute(null null -5px 50%);

                transform: translate(-50%, 0) rotate(45deg);
            }
        }
    }

    @include media($break768) {
        &__item {
            padding: 11px 25px;
        }
    }


    @include media($break992) {
        &__item:hover {
            text-decoration: none;
        }
    }
}
