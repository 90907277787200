.seriescarousel {
    &__item {
        margin-right: 5px;
        position: relative;

        &.slick-slide img {
            // height: 100%
            // width: 100%

        }

        &__like {
            @include absolute(null 10px 10px null);

            color: #fff;
        }
    }
}
