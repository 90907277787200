body {
    margin: 0;
    color: $c-text;
    font-family: $f-primary;
    font-size: 14px;
    line-height: $base-line-height;
    background-color: #fff;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    color: $c-text;

    @include media($break768) {
        font-size: $base-font-size;
    }
}

a {
    color: $c-main;
    text-decoration: underline;

    &:hover {
        color: $c-main-hover;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.upper {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

.hide {
    display: none;
}

@include media($break768M) {
    .hide--mobile {
        display: none !important;
    }
}


@include media($break768-992M) {
    .hide--tablet {
        display: none !important;
    }
}


@include media($break992) {
    .hide--desktop {
        display: none !important;
    }
}


@for $i from 0 through 15 {

    .mt-#{$i} {
        margin-top: $pixelRatio * $i + px
    }

    .mb-#{$i} {
        margin-bottom: $pixelRatio * $i + px
    }

}
