.listview {
    &__item {
        &__in {
            position: relative;
            z-index: 15;
        }

        &__more {
            transition: 0.5s ease opacity;

            @include absolute(0 0 0 100%);

            width: 100%;
            padding: 15px;
            z-index: 15;
            opacity: 0;
            pointer-events: none;
        }

        &__img {
            position: relative;

            img {
                display: block;
                width: 100%;
                max-height: 250px;
            }
        }

        &__carousel {
            pointer-events: none;

            .carousel__arrows,
            .slick-dots {
                opacity: 0;

                @include ts;
            }
        }

        &__labels {
            @include absolute(10px 10px null null);
            @include flex;
        }

        &__label {
            background-color: $c-text-dark;
            color: #fff;
            font-size: 12px;
            padding: 5px 9px;
            line-height: 1;
            margin-left: 10px;
        }

        &__actions {
            @include absolute(null 10px 10px null);

            z-index: 5;
            color: #fff;
        }

        &__footer {
            @include flex(space-between);

            padding-top: 5px;
        }

        &__title {
            margin: 0;
            color: $c-text;
            font-size: $base-font-size;

            span {
                color: $c-text-light;
                font-weight: normal;
                padding-left: 5px;
                font-size: 15px;
            }
        }

        &__price {
            color: $c-text-light;
            font-size: $base-font-size;
        }

        &--add {
            background-color: $c-main;
            position: relative;
            text-align: center;

            img {
                opacity: 0;
            }

            .listview__item__add {
                @include absolute-center;

                color: #fff;
                font-size: 30px;

                svg {
                    margin-bottom: 10px;

                    @include ts;
                }
            }
        }
    }

    @include media($break768M) {
        justify-content: center;

        &__item {
            max-width: 400px;
            margin: 0 auto 20px !important;

            &__title,
            &__price {
                font-size: 14px;
            }

            &__labels {
                top: 5px;
                right: 5px;
            }

            &__label {
                font-size: 10px;
                margin-left: 5px;
            }
        }
    }


    @include media($break992) {
        &__item {
            &--add:hover {
                .listview__item__add svg {
                    transform: scale(1.1) rotate(90deg);
                }
            }

            &--morehover {
                .listview__item__img {
                    //

                }

                &:before {
                    content: "";

                    @include absolute((-10px) null -5px -10px);

                    width: calc(200% + 30px);
                    background-color: $c-bg;
                    box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
                    z-index: 10;
                    opacity: 0;
                    pointer-events: none;
                    transition: 0.5s ease opacity;
                }

                &.is-hoverdelay {
                    &:before {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    .listview__item {
                        &__in {
                            //

                        }

                        &__more {
                            opacity: 1;
                            pointer-events: auto;
                        }

                        &__carousel {
                            pointer-events: auto;

                            .carousel__arrows,
                            .slick-dots {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.is-hover {
                    &:before {
                        z-index: 20;
                    }

                    .listview__item {
                        &__in {
                            z-index: 25;
                        }

                        &__more {
                            z-index: 30;
                        }

                        &__carousel {
                            .carousel__arrows,
                            .slick-dots {
                                //

                            }
                        }
                    }

                    &.offsethover-is-active {
                        &:before {
                            right: -10px;
                            left: auto;
                        }

                        .listview__item {
                            &__more {
                                left: calc(-100% - 20px);
                                width: calc(100% + 20px);
                            }
                        }
                    }
                }
            }
        }
    }
}
