.historydrop {
    margin: 0 0 0 -15px;

    &__item {
        @include flex(space-between, center);

        padding: 10px;
        position: relative;

        &:after {
            content: "";

            @include absolute(null 0 0 10px);

            border-bottom: 1px solid #dbdbdb;
        }

        &__left {
            @include flex(flex-start, center);
        }

        &__img {
            margin-right: 10px;
        }

        &__title {
            font-size: 15px;
        }

        &__date {
            font-size: 14px;
            color: $c-text-light;
        }

        &:hover {
            background-color: #f4f4f4;
        }
    }
}
