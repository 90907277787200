.colors {
	@include flex;

	flex-wrap: wrap;

	&__item {
		margin-right: 30px;

		@include flex(flex-start, center);

		&__color {
			@include size(26px);

			border-radius: 50%;
			margin-right: 7px;
		}
	}

	@include media($break768M) {
		&__item {
			margin-bottom: 15px;
		}
	}
}
