.blog {
    &__header {
        background-color: $c-bg;

        &__img {
            max-width: 1100px;
            width: 100%;
        }
    }

    &__content {
        position: relative;

        &__date {
            margin: 20px 0;
        }

        &__in {
            max-width: 900px;
        }

        &__share {
            //

        }

        &__gallery {
            margin-top: 30px;
        }

        &__perex {
            font-size: 20px;
        }
    }

    @include media($break768M) {
        &__content {
            &__gallery {
                margin-bottom: 40px;
            }
        }
    }


    @include media($break768) {
        &__content {
            padding-left: 110px;

            &__gallery {
                margin-top: 60px;
            }

            &__share {
                @include absolute(43px null null 25px);
            }
        }
    }
}
