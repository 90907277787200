.circlebtn {
    @include size(29px);

    background-color: $c-secondary;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: #fff;

    svg {
        @include absolute-center;
    }

    @include media($break992) {
        @include ts;

        &:hover {
            color: #fff;
            background-color: #ff2c43;
        }
    }
}
