.loginformseparator {
    position: relative;

    &__text {
        color: #aaaaaa;

        p {
            margin: 0;
            background-color: #fff;
        }
    }

    &__bg {
        width: 1px;
        background: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 30%, #e0e0e0 70%, #ffffff 100%);

        @include absolute(0 18px 0 null);
    }

    &--popup945 {
        .loginformseparator__text {
            right: 40px;
        }
    }

    @include media($break768M) {
        &__text {
            p {
                text-align: center;
                margin-top: 35px;
            }
        }

        &__bg {
            display: none;
        }
    }


    @include media($break768) {
        &__text {
            @include absolute(0 0 null null);

            width: 36px;
            height: 100%;
            min-height: 260px;

            p {
                @include absolute(65px 0 null 0);

                padding: 15px 0;
            }
        }
    }


    @include media($break768-992M) {
        &__text {
            right: -11px;
        }

        &--popup945 {
            .loginformseparator__text {
                right: -11px;
            }
        }
    }
}
