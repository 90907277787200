.imageblocks {
    &__item {
        &__img {
            display: block;
            margin-bottom: 20px;
        }

        &__title {
            font-weight: normal;
            font-size: 26px;
            margin: 0;
        }

        &__text {
            margin-top: 20px;
            font-size: 15px;

            p {
                margin: 0;
            }

            p.mt-2 {
                margin-top: 16px;
            }
        }

        &__table {
            table {
                td {
                    padding-right: 10px;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        &--mobile {
            margin-top: 20px;
        }
    }
}
