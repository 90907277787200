.page {
    padding-bottom: 40px;
    overflow: hidden;

    &__header {
        padding: 20px 0 25px;
        position: relative;

        &__nav {
            margin-bottom: 15px;
        }

        &__title.title {
            margin: 0;
        }

        &__middle {
            text-align: center;
        }

        &--offset {
            padding: 20px 0;
        }

        &--offset2x {
            padding: 40px 0;
        }

        &--npb {
            padding-bottom: 0;
        }
    }

    @include media($break992M) {
        &__header {
            &__middle {
                margin-top: 10px;
            }
        }
    }


    @include media($break992) {
        padding-bottom: 80px;

        &__header {
            &__middle {
                @include absolute-center;
            }

            &--offset {
                padding: 40px 0;
            }

            &--offset2x {
                padding: 60px 0;
            }
        }

        &__cols {
            @include flex(space-between);
        }

        &__col--75 {
            width: calc(75% - 20px);
        }

        &__col--25 {
            width: calc(25% - 20px);
        }
    }
}
