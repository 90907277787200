$selectric-main-color:      #DDD !default;                                              // Color used in border
$selectric-secondary-color: #BBB !default;                                              // Color used in button
$selectric-text-color:      #444 !default;                                              // Color used in label
$selectric-bg-color:        #F8F8F8 !default;                                           // Background color
$selectric-btn-bg-color:    #F8f8f8 !default;                                           // Button background color
$selectric-height:          40px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       16px !default;                                              // Font size

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric-required {
  .selectric .label .required-sign {
    display: inline-block;
  }
}

.selectric {
  width: 270px;
  display: flex;
  background: #0e88af;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #036b88;
  height: 40px;
  box-shadow: inset 2px 2px 5px 0 #056a88;

  &.error {
    border: 2px red solid;
  }

  &.value-selected {

    .label {
      color: #2d2c2a;
    }
    .required-sign {
      display: none !important;
    }

  }

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //height: 100%;
    user-select: none;
    padding: 0 20px;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    font-weight: normal;

    span {
      /*color: $orange;
      font-family: muli_bold;*/
    }

    .required-sign {
        display: none;
		margin-left: 4px;
		color: red;
        font-style: normal;
    }

  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    background-color: #0e84a8;
    border-left: 1px #08789e solid;
    margin-left: auto;
  }

  .arrow-button {
    width: 16px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    margin: auto;
    background: url("../images/icons/chevron-down.svg");
  }
}


.selectric-open {
  z-index: 20;

  .selectric-items {
    display: block;
  }

  .button {
    transform: rotate(180deg);
  }

}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% - 15px);
  left: 0;
  background: #fff;
  z-index: -1;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  border: 1px solid #036b88;
  border-top: 0;
  padding-top: 15px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 6px 15px;
    cursor: pointer;
    border-bottom: 1px #dedede solid;
    border-left: 3px transparent solid;

    &:first-child {
      //display: none;
    }

    &:hover,
    &.selected,
    &.highlighted {
      background-color: #e7e7e7;;
      //border-left: 3px $orange solid;
    }

  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
