.actions {
    @include flex;

    &__item {
        margin-right: 10px;
        cursor: pointer;
        margin: 0;
        border: 0;
        padding: 0;
        background: none;

        @include size(20px);
        @include flex(center, center);
        @include ts;

        &:last-child {
            margin-right: 0;
        }

        &--trash {
            color: $c-secondary;

            &:hover {
                color: $c-secondary;
            }
        }
    }

    @include media($break992) {
        &__item:hover:not(.hovernav) {
            transform: scale(1.2);
        }
    }
}
