.form {
    &__title {
        font-weight: 500;
        color: $c-text-dark;
        margin-bottom: 20px;
    }

    &__row {
        margin-bottom: 15px;

        &--offset {
            margin-top: 30px;

            &2x {
                margin-top: 45px;
            }
        }

        &--split {
            @include flex(space-between);
        }
    }

    &--350w {
        max-width: 350px;
        width: 100%;
    }

    &--center {
        margin: 0 auto;
    }

    @include media($break768) {
        &__row {
            &--offset {
                margin-top: 30px;

                &2x {
                    margin-top: 45px;
                }
            }

            &--split {
                @include flex(space-between);
            }
        }
    }
}
