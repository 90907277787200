.share {
    &__item {
        display: block;
        margin-bottom: 10px;

        @include ts;
    }

    @include media($break768M) {
        &__item {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 0;
        }
    }


    @include media($break992) {
        &__item:hover {
            transform: scale(1.1);
        }
    }
}
