.sizeformats {
    @include flex(flex-start, flex-end);

    flex-wrap: wrap;

    &__item {
        margin-right: 30px;

        &__object {
            background-color: #ebebeb;
        }

        &__desc {
            margin-top: 10px;
        }
    }

    @include media($break768M) {
        &__item {
            margin-bottom: 15px;
        }
    }
}
