.hovernav {
    position: relative;

    &__list {
        opacity: 0;

        @include absolute(100% null null -12px);

        transform: translate(0, -5px);
        z-index: 50;
        box-shadow: 3px 3px 0 0 #DBDBDB;
        width: 280px;
        pointer-events: none;

        @include ts;
    }

    &__item {
        @include flex;

        background-color: #F4F4F4;
        border-bottom: 1px solid #DBDBDB;
        color: $c-text-dark;
        text-decoration: none;
        padding: 7px 30px 7px 10px;
        font-size: 15px;

        @include ts;

        &:hover {
            text-decoration: none;
        }

        &__icon {
            text-align: center;
            width: 20px;
            margin-right: 10px;
            position: relative;
            bottom: 1px;
        }

        &:last-child {
            border: none;
        }
    }

    &.is-hover {
        .hovernav__list {
            opacity: 1;
            transform: translate(0, 0);
            pointer-events: auto;
        }
    }

    &.offsethover-is-active {
        .hovernav__list {
            left: auto;
            right: -12px;
        }
    }
}
