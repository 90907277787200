.quotecarousel {
    @include media($break768M) {
        .quote__item {
            margin: 0 25px;
        }
    }


    @include media($break768) {
        .quote__item {
            margin-right: 20px;
        }
    }
}
