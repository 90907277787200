.showroom {
    &__item {
        position: relative;
        overflow: hidden;

        &__label {
            @include absolute-center;

            background-color: rgba($c-text-dark, 0.85);
            color: #fff;
            width: auto;
            font-size: 18px;
            padding: 14px 24px;
        }

        &__img {
            @include ts;
        }
    }

    @include media($break768) {
        &__label {
            width: auto;
            font-size: 26px;
            padding: 21px 49px;
        }
    }


    @include media($break992) {
        &__item:hover {
            .showroom__item {
                &__img {
                    transform: scale(1.05);
                }
            }
        }
    }
}
