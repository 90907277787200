.container {
    margin: 0 auto;
    max-width: 1550px;
    width: calc(100% - 20px);

    &--900 {
        max-width: 900px;
    }

    &--630 {
        max-width: 630px;
    }

    @include media($break768) {
        width: calc(100% - 40px);
    }

    @include media($break992) {
        width: 98%;
    }
}
