.like {
    position: relative;
    white-space: nowrap;

    svg {
        margin-right: 10px;
    }

    span {
        font-size: 12px;
    }

    &--blue {
        svg {
            color: $c-main;
        }
    }

    &--white {
        svg {
            color: #fff;
        }
    }

    @include media($break768) {
        svg {
            @include ts;
        }

        &:hover {
            cursor: pointer;

            svg {
                transform: scale(1.2);
            }
        }
    }
}
