.inlineform {
    &__btns {
        .btn {
            //

        }
    }

    &__separator {
        color: #aaaaaa;
        margin-top: 40px;
    }

    @include media($break768M) {
        &__btns {
            .btn {
                margin: 5px;
            }
        }
    }


    @include media($break768) {
        &__btns {
            .btn {
                margin: 0 15px;
            }
        }
    }
}
