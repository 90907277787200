.rowgallery {
    &__row {
        @include flex;

        margin-bottom: 5px;

        div {
            margin-left: 5px;
            max-width: 32%;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
