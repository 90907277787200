.search {
    border-bottom: 1px solid #d3d3d3;
    padding: 0 10px;

    @include flex(space-between);

    white-space: nowrap;

    &__input,
    &__btn {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        outline: 0;
    }

    &__input {
        width: 100%;
        max-width: 200px;
    }

    &__btn {
        color: $c-main;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;

        svg {
            margin-right: 6px;
            position: relative;
            bottom: 2px;
        }
    }

    @include media($break992M) {
        &__btn {
            span {
                display: none;
            }
        }
    }
}
