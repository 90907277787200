.icon {
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    fill: currentColor;
    position: relative;

    &-logo-flowa {
        @include size(117px, 51px);
    }

    &-togglenav {
        @include size(38px, 33px);
    }

    &-arrow {
        &-down,
        &-down_dark {
            @include size(12px, 7px);
        }

        &-right {
            @include size(6px, 11px);

            &_text {
                @include size(5px, 7px);
            }
        }

        &-more {
            @include size(8px, 4px);
        }
    }

    &-heart,
    &-heart-fill,
    &-heart-blue,
    &-heart-white {
        @include size(21px, 18px);

        stroke: currentColor;
        fill: none;

        &.icon--filled {
            fill: currentColor;
        }
    }

    &-history {
        @include size(21px, 19px);
    }

    &-eye {
        @include size(18px, 19px);
    }

    &-search {
        @include size(19px);
    }

    &-quote {
        @include size(35px, 28px);
    }

    &-calendar {
        @include size(14px, 15px);
    }

    &-facebook {
        @include size(11px, 20px);
    }

    &-google {
        @include size(20px);
    }

    &-tip {
        @include size(20px, 17px);
    }

    &-share-fb,
    &-share-tw,
    &-share-email {
        @include size(40px);
    }

    &-arrow-carousel,
    &-arrow-carousel_blue {
        @include size(14px, 26px);
    }

    &-fullscreen {
        @include size(10px);
    }

    &-help,
    &-info {
        @include size(17px);

        fill: #000;
    }

    &-pencil {
        @include size(14px);
    }

    &-share {
        @include size(12px, 13px);
    }

    &-trash {
        @include size(8px, 14px);
    }

    &-add {
        @include size(50px);
    }

    &-save {
        @include size(10px, 11px);

        &.icon--large {
            @include size(15px);
        }
    }

    &-inbox {
        @include size(13px, 11px);
    }

    &-close,
    &-close-dark {
        @include size(8px);
    }

    &-close-thin {
        @include size(21px);
    }

    &-pin {
        @include size(82px, 61px);
    }

    &-check {
        @include size(10px, 7px);
    }

    &-blank {
        @include size(10px, 8px);
    }
}
