.highlights {
    &__item {
        &__img {
            @include size(110px, 80px);

            margin: 0 auto;
            position: relative;

            svg {
                max-height: 75px;
                max-width: 110px;

                @include absolute-center;
            }
        }

        &__title {
            color: $c-text-dark;
            font-size: 18px;
            font-weight: 500;
        }

        &__desc {
            color: $c-text-light;

            p {
                margin: 0;
            }
        }

        &__in {
            position: relative;
            z-index: 5;
        }
    }

    @include media($break768M) {
        &__list {
            padding: 15px 0;
        }

        &__item {
            @include flex;

            text-align: left;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &__img {
                margin-right: 20px;
            }
        }
    }


    @include media($break768) {
        &__item {
            &__img {
                @include size(150px);

                svg {
                    max-height: 85px;
                    max-width: 130px;
                }

                &:before {
                    content: "";

                    @include absolute-center;
                    @include size(250px);

                    background-color: $c-bg;
                    border-radius: 50%;
                }
            }

            &__desc {
                max-width: 80%;
                margin: 0 auto;
            }
        }
    }
}
