.field {
    position: relative;

    &__el {
        width: 100%;
        padding: 10px 20px 10px 40px;
        line-height: 1.2;
        border-radius: 3px;
        font-size: 15px;
        border: 1px solid #d3d3d3;
        color: $c-text-light;
        outline: 0;

        @include ts;

        &[disabled] {
            border: 1px solid #d3d3d3;
            background-color: #f8f8f8;
            color: #afafaf;
            pointer-events: none;
        }

        &:hover {
            border-color: #b9b7b7;
        }

        &:focus {
            border-color: $c-main;
        }
    }

    &__error {
        color: #dc0c23;
        font-size: 13px;
        padding-top: 5px;
        line-height: 1.3;
        padding-left: 12px;
        font-style: italic;

        @include absolute(null 0 -17px 0);
    }

    &__icon {
        @include absolute(0 null 0 0);

        width: 40px;

        svg {
            @include absolute(10px null null 10px);

            max-width: 19px;
            max-height: 17px;
        }
    }

    &--withouticon {
        .field {
            &__el {
                padding-left: 12px;
            }
        }
    }
}
