.mobilenav {
    @include absolute(68px 0 null 0);

    background-color: #fff;
    padding: 25px 10px;
    display: none;

    &__in {
        max-width: 400px;
        margin: 0 auto;
    }

    &__button {
        position: relative;
        margin-bottom: 30px;
    }

    &__cols {
        @include flex(space-between);

        margin-bottom: 20px;

        &__item {
            position: relative;
            width: calc(50% - 5px);
        }
    }

    .nav-is-open & {
        display: block;
    }
}
