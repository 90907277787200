.series {
    &__header {
        @include flex(space-between, center);
    }

    &__perex {
        &__attr {
            line-height: 1.6;
            margin: 15px 0;

            span {
                color: #929292;
            }
        }
    }

    &__group {
        margin-bottom: 20px;
    }

    @include media($break768M) {
        &__carousel {
            margin: 0 -10px;
        }
    }


    @include media($break768) {
        &__group {
            margin-bottom: 50px;
        }
    }
}
