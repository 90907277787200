.checkbox {
    @include flex(flex-start, flex-start);

    user-select: none;
    margin: 0;

    &__icon {
        margin-right: 10px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;

        input {
            @include absolute(-100px);
        }

        &__square {
            @include size(22px);

            border-radius: 3px;
            border: 1px solid rgba(#000, 0.2);
            background-color: #fff;
            position: relative;

            @include ts;

            &:before,
            &:after {
                content: "";
                height: 3px;
                background-color: $c-main;
                opacity: 0;

                @include ts;
            }

            &:before {
                width: 6px;

                @include absolute(9px null null 5px);

                transform: rotate(40deg);
            }

            &:after {
                width: 10px;

                @include absolute(8px null null 7px);

                transform: rotate(-40deg);
            }
        }

        input:checked + .checkbox__icon__square {
            &:before,
            &:after {
                opacity: 1;
            }
        }
    }

    &__label {
        font-size: 14px;

        span {
            margin-right: 5px;
        }

        small {
            color: $c-text-light;
            font-size: 14px;
            padding-left: 5px;
        }
    }

    &:hover {
        .checkbox__icon__square {
            border: 1px solid rgba(#000, 0.5);
        }
    }

    &.is-hidden {
        display: none;
    }

    &--small {
        .checkbox {
            &__icon {
                &__square {
                    @include size(20px);

                    border-radius: 6px;

                    &:before {
                        left: 4px;
                    }

                    &:after {
                        left: 6px;
                    }
                }
            }
        }
    }

    &--labellarge {
        .checkbox__label {
            font-size: 16px;
        }
    }

    &--error {
        position: relative;
        margin-bottom: 30px;

        .checkbox__icon__square {
            border-color: #e14e5e;
        }

        .field__error {
            padding-left: 33px;
        }
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    @include media($break768M) {
        &__icon {
            width: 35px;
        }
    }
}
