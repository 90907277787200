.bloglist {
    &__item {
        position: relative;

        @include flex(flex-start, flex-start);
        @include ts;

        &__img {
            margin-right: 20px;
            flex-shrink: 0;
            overflow: hidden;

            img {
                @include ts;
            }
        }

        &__date {
            //

        }

        &__title.title {
            margin: 5px 0 2px;
        }

        &__desc {
            svg {
                margin-left: 5px;

                @include ts;
            }
        }
    }

    &--nav {
        @include flex(space-between);

        border-top: 1px solid #e2e2e2;
        padding: 30px 0;

        .bloglist__item {
            max-width: 530px;

            &:last-child {
                text-align: right;

                img {
                    order: 2;
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
        }
    }

    @include media($break768M) {
        &__item {
            width: 50%;

            &__desc {
                font-size: 13px;
            }

            &__img {
                display: none;
            }
        }
    }


    @include media($break768) {
        &--nav {
            padding: 60px 0 70px;
        }
    }


    @include media($break992) {
        &__item:hover {
            .bloglist__item__img img {
                transform: scale(1.05);
            }

            .icon-arrow-right_text {
                transform: translate(5px, 0);
            }
        }
    }
}
