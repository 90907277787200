.helper {
    margin-left: 5px;
    cursor: help;
    position: relative;
    pointer-events: auto;
    outline: 0;
}

.tippy-popper {
    background: #fff;
    padding: 15px;
    border: 1px solid #dbdbdb;
    color: #000;

    a {
        color: #0a89ae;

        &:hover {
            text-decoration: none;
        }
    }
}
