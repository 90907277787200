.cols {
    @include media($break768M);

    > .cols__item {
        position: relative;
    }

    @include media($break768M) {
        &mob {
            @include flex(flex-start);

            flex-wrap: wrap;

            > .cols__item {
                margin-right: 10px;
                margin-bottom: 10px;

                &--right {
                    @include flex(flex-end);
                }
            }

            &--6 {
                > .cols__item {
                    width: calc(100% / 6 - 40px);
                    margin-right: 40px;

                    &:nth-child(6n) {
                        margin-right: 0;
                    }
                }
            }

            &--4 {
                > .cols__item {
                    width: calc(100% / 4 - 15px);

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }
            }

            &--3 {
                > .cols__item {
                    width: calc(100% / 3 - 15px);

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }

            &--2 {
                > .cols__item {
                    width: calc(100% / 2 - 5px);

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            &--1 {
                > .cols__item {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


    @include media($break768-992M) {
        &tablet {
            @include flex(flex-start);

            flex-wrap: wrap;

            > .cols__item {
                margin-right: 20px;
                margin-bottom: 20px;

                &--right {
                    @include flex(flex-end);
                }
            }

            &--6 {
                > .cols__item {
                    width: calc(100% / 6 - 40px);
                    margin-right: 40px;

                    &:nth-child(6n) {
                        margin-right: 0;
                    }
                }
            }

            &--4 {
                > .cols__item {
                    width: calc(100% / 4 - 15px);

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }
            }

            &--3 {
                > .cols__item {
                    width: calc(100% / 3 - 15px);

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }

            &--2 {
                > .cols__item {
                    width: calc(100% / 2 - 10px);

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            &--1 {
                > .cols__item {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


    @include media($break992) {
        @include flex(flex-start);

        flex-wrap: wrap;

        > .cols__item {
            margin-right: 20px;
            margin-bottom: 20px;

            // IE11 bug
            _:-ms-fullscreen, :root & {
                margin-right: 19px;
            }

            &--right {
                @include flex(flex-end);
            }
        }

        &--6 {
            > .cols__item {
                width: calc(100% / 6 - 40px);
                margin-right: 40px;

                &:nth-child(6n) {
                    margin-right: 0;
                }
            }
        }

        &--4 {
            > .cols__item {
                width: calc(100% / 4 - 15px);

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }

        &--3 {
            > .cols__item {
                width: calc(100% / 3 - 15px);

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }

        &--2 {
            > .cols__item {
                width: calc(100% / 2 - 10px);

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}
