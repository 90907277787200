.footer {
	background-color: $c-bg-dark;
	padding: 30px 0;

	&__in {
		//

	}

	&__item {
		//

	}

	&__copy {
		color: #fff;
		margin: 0;
		font-size: 12px;
	}

	&__nav {
		&__item {
			color: #8d8c8c;
			text-decoration: none;
			margin-right: 25px;
			position: relative;

			@include ts;

			&:after {
				content: "";

				@include size(4px);

				background-color: #8d8c8c;
				border-radius: 50%;

				@include absolute(50% -12px null null);

				transform: translate(0, -50%);
			}

			&:before {
				content: "";

				@include absolute(null 0 -2px 0);

				border-bottom: 1px dotted currentColor;
				opacity: 0.5;
			}

			&:last-child:after {
				display: none;
			}
		}
	}

	&__social {
		&__item {
			margin-left: 10px;
			color: #706F6F;
			text-decoration: none;

			@include ts;

			svg {
				@include size(25px);
			}
		}
	}

	@include media($break992M) {
		position: relative;
		padding: 20px 0;

		&__item {
			&--first {
				@include flex;

				flex-wrap: wrap;
			}

			&--social {
				@include absolute(null 10px 16px null);
			}
		}

		&__copy {
			//

		}

		&__nav {
			order: -1;
			text-align: center;
			border-bottom: 1px solid rgba(#fff, 0.1);
			margin-bottom: 20px;
			padding: 0 20px 20px;
			width: 100%;

			&__item {
				margin: 5px 25px 5px 0;
				display: inline-block;
				line-height: 1;
			}
		}
	}


	@include media($break768M) {
		&__nav {
			&__item {
				margin: 5px 10px;

				&:after {
					display: none;
				}
			}
		}
	}


	@include media($break992) {
		&__in {
			@include flex(space-between, center);
		}

		&__item {
			@include flex(flex-start);
		}

		&__copy {
			font-size: $base-font-size;
		}

		&__nav {
			margin-left: 30px;
		}

		&__social {
			&__item {
				margin-left: 20px;

				svg {
					@include size(30px);
				}
			}
		}
	}


	@include media($break768-992M) {
		&__social {
			&__item {
				margin-left: 20px;
			}
		}
	}


	@include media($break992) {
		&__nav {
			&__item:hover {
				color: #fff;
				text-decoration: none;
			}
		}

		&__social {
			&__item:hover {
				color: #fff;
			}
		}
	}
}
