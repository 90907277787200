.historylist {
    &__submit {
        margin: 30px 0;
    }

    &__item {
        border-bottom: 1px solid #dbdbdb;
        padding: 10px 0;

        @include flex(space-between, center);

        &__data {
            @include flex(flex-start, center);
        }

        &__img {
            margin-right: 10px;
        }

        &__point {
            margin-right: 10px;
            position: relative;

            &:after {
                content: "";

                @include size(2px);

                background-color: $c-text-light;

                @include absolute(50% -15px null null);

                transform: translate(0, -50%);
                border-radius: 50%;
                opacity: 0.5;
                display: none;
            }

            img {
                margin-right: 7px;

                @include relative(null null 2px null);
            }

            &--light {
                color: $c-text-light;
            }

            &:last-child:after {
                display: none;
            }
        }

        &__time {
            color: $c-text-light;
        }
    }

    @include media($break768M) {
        &__item {
            &__img {
                width: 50px;
                flex-shrink: 0;
            }

            &__time {
                font-size: 11px;
            }
        }
    }


    @include media($break768) {
        &__submit {
            margin: 50px 0;
        }

        &__item {
            padding: 10px 20px;

            &__img {
                margin-right: 20px;
            }

            &__point {
                margin-right: 30px;

                &:after {
                    display: block;
                }
            }
        }
    }
}
