.sticky {
    padding: 8px 0;
    position: relative;
    z-index: 10005;
    background-color: #fff;

    &__in {
        @include flex(space-between, center);

        @include media($break768M) {
            display: block;
        }

        &__item {
            @include flex(flex-end, center);

            @include media($break768M) {
                display: block;
            }
            
        }
    }

    &__phone,
    &__mail {
        margin-right: 24px;
    }

    .icon-phone,
    .icon-mail {
        width: 25px;
        height: 25px;
    }

    .selectric-wrapper {
        margin-right: 30px;
    }

    &__logo {
        @include flex(flex-start, center);

        margin-right: 40px;

        &__claim {
            color: $c-text-light;
            font-size: $base-font-size;
            margin-left: 20px;
        }
    }

    &__button {
        margin-right: 20px;
    }

    &__actions {
        @include flex(flex-end, center);

        &__item {
            margin-left: 10px;

            &:first-item {
                margin-left: 0;
            }
        }

        &__btn {
            position: relative;
            cursor: pointer;

            svg {
                margin-right: 15px;
            }

            span {
                background-color: $c-secondary;
                color: #fff;
                padding: 3px 6px;
                font-size: 11px;
                font-weight: bold;
                letter-spacing: -1px;
                line-height: 1;
                border-radius: 15px;
                box-shadow: 0 0 0 3px #fff;

                @include absolute((-4px) 0 null null);
            }
        }
    }

    @include media($break992M) {
        border-bottom: 1px solid #DBDBDB;

        &__nav {
            display: none;
        }
    }


    @include media($break992) {
        padding: 13px 0;

        .is-page404 & {
            border-bottom: 1px solid #DBDBDB;
        }
    }


    @include media($break1200-1300M) {
        &__button {
            .btn--dropdown {
                font-size: 14px;
            }
        }
    }


    @include media($break1200M) {
        &__button {
            display: none;
        }
    }


    @include media($break1600M) {
        &__logo__claim {
            display: none;
        }
    }
}
