.page404 {
    margin: 30px 0;

    &__img {
        position: relative;

        img {
            @include absolute;

            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        padding: 20px 0;
        position: relative;
        z-index: 10;
    }

    &__claim {
        padding: 20px 0;
        background-color: #fff;
        border-radius: 20px;

        h3 {
            color: #dc0b23;
            font-family: $f-primary;
            font-size: 40px;
            line-height: 1.1;
            font-weight: 900;
            letter-spacing: -1px;
            margin: 0;
        }

        p {
            font-size: 18px;
            margin: 10px 0 0;
        }
    }

    &__text {
        font-size: 17px;
        padding-top: 20px;

        p {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__link {
        color: #979797;
        font-size: 22px;
    }

    @include media($break768M) {
        &__img {
            height: 200px;
            width: 100%;
        }
    }


    @include media($break768) {
        margin: 60px 0;

        @include flex;

        &__img {
            width: 50%;
        }

        &__content {
            width: 50%;
            padding: 40px 0;
        }

        &__claim {
            margin-left: -180px;
            width: calc(100% + 180px);
            padding: 20px 35px 30px;

            h3 {
                font-size: 50px;
            }

            p {
                font-size: 20px;
            }
        }

        &__text {
            padding-left: 40px;
        }
    }


    @include media($break992) {
        &__claim {
            h3 {
                font-size: 56px;
            }
        }
    }
}
