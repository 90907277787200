.stickynav {
    @include media($break992M) {
        margin: 20px 0 0;

        &__item {
            text-decoration: none;
            text-transform: uppercase;
            display: block;
            text-align: center;
            padding: 10px 0;
            margin: 10px 0;
        }
    }


    @include media($break992) {
        @include flex;

        &__item {
            text-decoration: none;
            text-transform: uppercase;
            color: $c-text-dark;
            margin-right: 20px;
            position: relative;

            @include ts;

            &:after {
                content: "";

                @include size(4px);

                background-color: #d7d7d7;
                border-radius: 50%;

                @include absolute(50% -12px null null);

                transform: translate(0, -50%);
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            &:hover {
                color: $c-main;
                text-decoration: none;
            }
        }
    }
}
