.attrs {
    line-height: 1.6;

    &__light {
        color: $c-text-light;

        a {
            color: currentColor;
            text-decoration: none;

            @include ts;

            &:hover {
                color: $c-main;
            }
        }
    }

    &__eye {
        margin-left: 5px;
    }

    &__title {
        font-size: 12px;
        font-weight: bold;
    }

    &__price {
        font-size: 16px;
        font-weight: bold;
    }

    &__discountprice {
        color: $c-main;
    }

    &__oldprice {
        color: $c-text-light;
        text-decoration: line-through;
    }

    &__btn {
        margin-top: 10px;
    }

    &__row {
        span {
            color: $c-text-light;
            padding-right: 5px;
        }
    }

    &__group {
        margin-bottom: 30px;
    }

    &--offsetbtn {
        .attrs__btn {
            margin-top: 30px;
        }
    }

    &--compact {
        line-height: 1.4;
    }

    @include media($break768M) {
        font-size: 14px;
    }


    @include media($break768) {
        &__title {
            font-size: 14px;
        }

        &__price {
            font-size: 20px;
        }
    }
}
