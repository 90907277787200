.content {
    table {
        td {
            vertical-align: top;
            padding-right: 90px;

            &:last-child {
                padding-right: 0;
            }
        }
    }
}
