.rowform {
    max-width: 850px;
    margin: 30px auto;

    &__row {
        margin-bottom: 15px;
        display: block;
    }

    &__label {
        &__value {
            font-size: 15px;
            color: $c-text-dark;
            font-weight: bold;
            user-select: none;
        }
    }

    &__field {
        //

    }

    &__box {
        background-color: $c-bg;
        border: 1px solid #dbdbdb;
        padding: 20px 0;
        margin: 30px 0;

        &--inline {
            padding-top: 0;
        }
    }

    &__title {
        font-size: 22px;
        color: $c-text-dark;
        margin-bottom: 20px;
    }

    &__row {
        &--error {
            .field__el {
                background-color: #FCDBDF;
                border: 1px solid #e14e5e;
                color: #dc0c23;
                margin-bottom: 5px;
            }
        }
    }

    @include media($break768M) {
        &__label {
            padding-bottom: 2px;
        }
    }


    @include media($break768) {
        margin: 60px auto;

        &__row {
            @include flex(center, center);

            margin-bottom: 25px;

            &--long {
                align-items: flex-start;

                .rowform__label {
                    margin-top: 1px;
                }

                .rowform__field__toggle {
                    margin-top: 10px;
                }
            }
        }

        &__label {
            width: 30%;
        }

        &__field {
            width: 70%;
        }

        &__box {
            padding: 50px 0 40px;
            margin: 50px 0;

            &--inline {
                padding-top: 20px;
                margin-top: 30px;
            }
        }

        &__title {
            margin-bottom: 40px;
        }
    }
}
