.section {
    &__btn {
        margin-top: $section-spacing;
    }

    &__header {
        @include flex(space-between, flex-start);
    }

    &--bg {
        background-color: $c-bg;

        &alt {
            background-color: #e5e5e5;
        }
    }

    @include media($break768M) {
        &--margin {
            margin: ($section-spacing / 2) 0;

            &2x {
                margin: $section-spacing 0;
            }
        }

        &--padding {
            padding: ($section-spacing / 2) 0;

            &2x {
                padding: $section-spacing 0;
            }
        }
    }


    @include media($break768) {
        &--margin {
            margin: $section-spacing 0;

            &2x {
                margin: ($section-spacing * 2) 0;
            }
        }

        &--padding {
            padding: $section-spacing 0;

            &2x {
                padding: ($section-spacing * 2) 0;
            }
        }
    }
}
