.article {
    &__item {
        &__img {
            overflow: hidden;

            img {
                transform: scale(1);

                @include ts;
            }
        }

        &__date {
            margin: 10px 0 -5px;
        }

        &__title.title {
            margin: 10px 0 2px;
        }

        &__desc {
            svg {
                margin-left: 5px;

                @include ts;
            }
        }

        &--hover {
            @include ts;

            &:hover {
                .article__item__img img {
                    transform: scale(1.05);
                }

                .icon-arrow-right_text {
                    transform: translate(5px, 0);
                }
            }
        }
    }
}
