.wishlistdrop {
    margin-bottom: 20px;

    // overflow: hidden
    &__item {
        @include flex(space-between, center);

        padding: 10px;
        position: relative;

        @include ts;

        > .coverlink {
            right: 80px;
        }

        &:after {
            content: "";

            @include absolute(null 0 0 0);

            border-bottom: 1px solid #dbdbdb;
        }

        &__left {
            @include flex(flex-start, center);
        }

        &__img {
            margin-right: 10px;
        }

        &__title {
            font-size: 15px;

            span {
                color: $c-text-light;
                font-size: 14px;
                padding-left: 3px;
            }
        }

        &__actions {
            position: relative;
        }

        &:hover {
            background-color: #f4f4f4;
        }
    }
}
