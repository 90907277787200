.loading {
    @include fixed(50% null null 50%);

    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(#fff, 0.9);
    padding: 20px;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;

    @include ts;

    .is-loading & {
        pointer-events: auto;
        opacity: 1;
    }

    svg {
        @include size(50px);

        animation: 3s spin infinite linear;
    }

    div {
        margin-top: 10px;
    }

    span {
        animation: 1.4s dot infinite linear;
        animation-fill-mode: both;
    }

    span:nth-child(1) {
        margin-left: 3px;
    }

    span:nth-child(2) {
        animation-delay: 0.2s;
    }

    span:nth-child(3) {
        animation-delay: 0.4s;
    }
}
