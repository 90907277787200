.popup {
    width: 96%;
    max-width: 600px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    margin: 10px auto;
    padding: 30px 20px 10px;
    position: relative;

    &__close {
        @include absolute(10px 15px null null);

        color: $c-main;

        @include flex(flex-start, center);

        cursor: pointer;

        @include ts;

        span {
            font-size: 20px;
            margin-right: 5px;
        }

        div {
            text-decoration: underline;
        }
    }

    &__footer {
        background-color: $c-bg;
        border-top: 1px solid #dbdbdb;
        margin: 0 -40px -10px;
        padding: 20px;

        &__claim {
            margin: 0;

            a {
                display: inline-block;
                margin: 0 5px;
            }
        }
    }

    &--555 {
        max-width: 555px;
    }

    &--820 {
        max-width: 820px;
    }

    &--945 {
        max-width: 945px;
    }

    @include media($break768M) {
        &__content {
            .cols {
                max-width: 400px;
                margin: 0 auto;

                .cols__item:first-child {
                    max-width: 285px;
                    margin: 0 auto;

                    .form__title {
                        text-align: center;
                    }
                }
            }

            .loginformseparator {
                // margin-bottom: 25px

            }
        }
    }


    @include media($break768) {
        padding: 30px 25px;

        &__footer {
            margin: 0 -40px -30px;
            padding: 30px;
        }
    }


    @include media($break992) {
        padding: 30px 40px;

        &__close:hover {
            color: $c-secondary;
        }
    }
}
