.quote {
    &__item {
        background-color: #e5e5e5;
        padding: 10px;

        &__text {
            margin: 10px 0;

            p {
                margin: 0;
            }
        }

        &__profile {
            @include flex(flex-start, center);

            margin-top: 30px;

            img {
                border-radius: 50%;
                margin-right: 15px;
            }

            p {
                margin: 0;
                color: $c-text-light;
            }
        }

        &--alt {
            background-color: $c-bg;
        }
    }

    @include media($break768M) {
        &__item {
            .icon-quote {
                max-width: 30px;
                height: 20px;
            }

            &__profile {
                img {
                    display: none;
                }
            }
        }
    }


    @include media($break768) {
        &__item {
            padding: 20px 40px;
        }
    }
}
