.dropdown {
    @include absolute(100% 0 null null);

    z-index: 10;
    user-select: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease opacity;

    &__parent {
        //

    }

    &__in {
        box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
        border: 1px solid #d7d7d7;
        background-color: #ffffff;
        padding: 20px 15px;
        position: relative;

        &:before {
            content: "";

            @include size(15px);

            background-color: #fff;
            border-top: 1px solid #d7d7d7;
            border-right: 1px solid #d7d7d7;

            @include absolute((-8px) null null 50px);

            transform: rotate(-45deg);
            border-radius: 2px;
        }
    }

    &__title {
        font-size: 18px;
        color: $c-text-dark;
        font-weight: bold;
        margin-bottom: 15px;
        text-decoration: none;

        span {
            font-size: 14px;
            color: $c-text-light;
            padding-left: 5px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__title {
        transition: 0.3s ease all;
    }

    &__nav {
        &__item {
            display: block;
            text-decoration: none;
            padding: 8px 15px;

            img {
                margin-right: 5px;
                position: relative;
                bottom: 1px;
            }

            svg {
                margin-left: 7px;
            }
        }
    }

    .is-toggled &,
    .is-hover & {
        opacity: 1;
        pointer-events: auto;
    }

    &--scroll {
        .dropdown__content {
            max-height: 500px;
            overflow: auto;
        }
    }

    &--nav {
        width: 100%;
        left: 0;
        padding-top: 0;

        .dropdown {
            &__in {
                border-radius: 0 0 20px 20px;
                border-top: 0;
                margin-top: -1px;
                padding: 0;

                &:before {
                    display: none;
                }
            }
        }
    }

    &--nav--border {
        .dropdown__nav__item {
            border-bottom: 1px solid #d7d7d7;
            padding-top: 10px;
            padding-bottom: 10px;

            &:last-child {
                border: none;
            }
        }
    }

    @include media($break768M) {
        width: 100%;
        left: 0;

        &__in:before {
            display: none;
        }
    }


    @include media($break768) {
        width: 430px;
        left: -50px;
        padding-top: 15px;
        z-index: 100;

        .offsethover-is-active & {
            left: auto;
            right: -50px;

            .dropdown__in:before {
                left: auto;
                right: 70px;
            }
        }

        &__parent {
            position: relative;
        }

        &--nav {
            width: 100%;
            left: 0;
            padding-top: 0;
        }
    }
}
