.labelnav {
    padding: 5px 0;

    &__text {
        b {
            font-size: 20px;
            line-height: 1;
        }
    }

    &__list {
        @include flex(flex-start, center);

        flex-wrap: wrap;
    }

    &__item {
        background-color: #ebebeb;
        padding: 5px 25px 5px 10px;
        border-radius: 20px;
        line-height: 1.1;
        white-space: nowrap;
        margin: 5px 5px 5px 0;
        text-decoration: none;
        color: $c-text;
        position: relative;

        svg {
            margin-left: 8px;

            @include absolute(50% 10px null null);

            transform: translate(0, -50%);

            @include ts;
        }
    }

    &__actions {
        //

    }

    @include media($break768M) {
        &__list {
            margin: 10px 0;
            flex-wrap: wrap;
        }

        &__item {
            margin: 2px;
        }
    }


    @include media($break768) {
        @include flex(flex-start, center);

        &__text {
            flex-shrink: 0;
        }

        &__list {
            margin-left: 15px;
        }

        &__item {
            padding: 7px 25px 7px 12px;
            margin-right: 15px;
        }

        &__actions {
            margin-left: 20px;
            flex-shrink: 0;
        }
    }


    @include media($break992) {
        &__item {
            @include ts;

            &:hover {
                color: $c-main;
                text-decoration: none;

                svg {
                    transform: translate(0, -50%) rotate(90deg);
                }
            }
        }
    }
}
