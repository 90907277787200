.mobiletable {
    @include media($break768M) {
        table {
            td {
                display: block;
                width: 100%;
                padding-right: 0;
            }
        }
    }
}
