.hr {
    margin: 60px 0;
    height: 1px;
    background-color: #e2e2e2;

    &.m-4-0 {
        margin: 32px 0;
    }

}
