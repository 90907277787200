.btn {
    display: inline-block;
    text-decoration: none;
    outline: none !important;
    border: 0;
    line-height: 1.3;
    cursor: pointer;

    @include ts;

    &:hover {
        text-decoration: none;
    }

    &--dropdown {
        box-shadow: inset 2px 2px 3px rgba(17, 16, 13, 0.2);
        border: 1px solid #056b88;
        background-color: $c-main;
        color: #fff;
        border-radius: 20px;
        overflow: hidden;

        @include flex(center);

        .btn__label {
            padding: 10px 20px 9px 15px;
            color: #fff;

            @include flex(flex-start, center);

            img {
                margin-right: 7px;
            }
        }

        .btn__icon {
            background-color: rgba(#000, 0.05);
            position: relative;
            width: 40px;
            border-left: 1px solid #056b88;

            @include ts;

            svg {
                @include absolute-center;
                @include ts;
            }
        }

        &--light {
            background: none;
            box-shadow: none;
            border: 1px solid #d7d7d7;

            .btn__label {
                color: $c-text-dark;
            }

            .btn__icon {
                background: none;
                border: none;
            }
        }

        .is-toggled & {
            border-radius: 20px 20px 0 0;
            box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.49);
        }
    }

    &--text {
        font-size: $base-font-size;

        span {
            text-decoration: underline;
        }

        svg {
            margin-right: 10px;
        }

        &--iconright {
            svg {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }

    &--wishlist,
    &--download {
        border: 1px solid $c-main;
        color: $c-main;
        line-height: 1.1;
        border-radius: 20px;
        font-size: 12px;

        .btn__in {
            @include flex(space-between, center);
        }

        .btn__label {
            padding: 9px 8px 8px 5px;
            font-weight: bold;
        }

        .btn__icon {
            margin-left: 10px;
            position: relative;
            bottom: 1px;
        }

        &--fill {
            background-color: $c-main;
            color: #fff;
        }
    }

    &--primary {
        background-color: $c-main;
        color: #fff;
        border-radius: 20px;

        .btn__in {
            @include flex(space-between, center);
        }

        .btn__label {
            padding: 11px 30px 10px;
            font-weight: bold;
        }

        .btn__icon {
            width: 50px;
            position: relative;
            margin-left: -30px;

            @include ts;

            svg {
                @include absolute-center;
            }
        }

        &--gray {
            background-color: $c-text-light;
        }

        &--arrowdown {
            .btn__icon {
                transform: rotate(90deg);
            }
        }

        &.btn--iconcenter {
            padding-left: 30px;
            padding-right: 20px;

            .btn__label {
                padding-left: 0;
                padding-right: 0;
            }

            .btn__icon {
                margin-left: 0;
                width: 30px;
            }

            .btn__in {
                justify-content: center;
            }
        }

        &:hover {
            color: #fff;
        }
    }

    &--social {
        color: #fff;
        border-radius: 20px;
        width: 100%;
        max-width: 285px;

        .btn__in {
            @include flex(flex-start, center);
        }

        .btn__label {
            padding: 11px 10px 10px;
            font-weight: bold;
        }

        .btn__icon {
            width: 26px;
            margin-left: 15px;
            text-align: center;
        }

        &--facebook {
            background-color: #3A5C97;
        }

        &--google {
            background-color: #DF2D23;
        }
    }

    &--add {
        text-decoration: underline;
    }

    &--full {
        width: 100%;
        text-align: center;

        .btn__label {
            display: block;
            margin: 0 auto;
        }
    }

    &--wide {
        .btn__label {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.btn--primary--arrowdown {
            .btn__in {
                @include flex(flex-start, center);
            }

            .btn__label {
                padding: 11px 10px 10px 60px;
                font-weight: bold;
            }

            .btn__icon {
                width: 26px;
                margin-left: 5px;
                text-align: center;
                margin-right: 60px;
            }
        }
    }

    &--wide2x {
        .btn__label {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @include media($break768M) {
        &--wishlist {
            svg {
                max-width: 16px;
            }
        }
    }


    @include media($break768) {
        &--primary {
            &.btn--iconcenter {
                padding-left: 70px;
                padding-right: 60px;
            }
        }

        &--wishlist,
        &--download {
            font-size: $base-font-size;
            white-space: nowrap;

            .btn__label {
                padding: 9px 15px 8px 10px;
            }

            .btn__icon {
                margin-left: 12px;
            }
        }

        &--wide {
            .btn__label {
                padding-left: 70px;
                padding-right: 70px;
            }
        }

        &--wide2x {
            .btn__label {
                padding-left: 120px;
                padding-right: 120px;
            }
        }
    }


    @include media($break992) {
        &--primary:hover {
            background-color: $c-main-hover;

            .btn__icon {
                transform: translate(5px, 0);
            }

            &.btn--primary--arrowdown {
                .btn__icon {
                    transform: translate(0, 3px) rotate(90deg);
                }
            }
        }

        &--dropdown:hover {
            color: #fff;
            text-decoration: none;

            .btn__icon {
                background-color: rgba(#fff, 0.07);
            }
        }

        &--download:hover {
            background-color: $c-main;
            color: #fff;
        }

        &--download--fill:hover {
            background-color: $c-main-hover;
        }

        &--dropdown--light:hover {
            .btn__icon {
                transform: translate(0, 2px);
            }
        }

        &--social:hover {
            color: #fff;

            &.btn--social--facebook {
                background-color: #4873bd;
            }

            &.btn--social--google {
                background-color: #f73227;
            }
        }
    }
}
