.title {
    margin: 0;
    padding: 0;
    font-weight: normal;

    &--article {
        font-size: $base-font-size;
        color: $c-main;
        line-height: 1.2;
        font-weight: bold;
    }

    &--section {
        color: $c-text-dark;
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: $section-spacing / 2;
    }

    &--group {
        font-size: 22px;
        color: $c-text-dark;
        margin-bottom: $section-spacing / 2;

        span {
            color: $c-text-light;
            font-size: 18px;
            padding-left: 6px;
        }
    }

    &__count {
        font-size: 20px;
        color: $c-text-light;
        display: inline-block;
        margin-left: 7px;
    }

    &__nav {
        display: inline-block;
        font-size: 20px;

        &__separator {
            display: inline-block;
            padding: 0 7px;
            color: $c-text-light;
        }
    }

    &__actions {
        display: inline-block;
        margin-left: 20px;
    }

    @include media($break768) {
        &--section {
            font-size: 30px;
            margin-bottom: $section-spacing;
        }
    }
}
