.fieldrow {
    &__item {
        &--error {
            .field__el {
                background-color: #FCDBDF;
                border: 1px solid #e14e5e;
                color: #dc0c23;
                margin-bottom: 5px;
            }

            .field__error {
                position: static;
                margin-bottom: -10px;
                padding-top: 2px;
            }
        }
    }

    @include media($break768M) {
        &__item {
            &--error {
                .field__el {
                    //

                }

                .field__error {
                    margin-bottom: 0;
                }
            }
        }

        &--2 {
            .fieldrow__item {
                margin-bottom: 10px;
            }
        }
    }


    @include media($break768) {
        @include flex(flex-start);

        flex-wrap: wrap;

        .fieldrow__item {
            margin-right: 20px;
        }

        &--2 {
            .fieldrow__item {
                width: calc(100% / 2 - 10px);

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}
