.carousel {
    &__wrapper {
        position: relative;
    }

    &__arrows {
        @include absolute(50% 0 null 0);

        z-index: 100;
        transform: translate(0, -50%);
        pointer-events: none;

        @include flex(space-between);

        &__item {
            pointer-events: auto;
            cursor: pointer;
            padding: 20px 10px;
            background-color: #000;

            &:first-child {
                transform: rotate(-180deg);
            }

            &.slick-disabled {
                opacity: .5;
                //pointer-events: none;
            }
        }
    }

    &__core {
        position: relative;

        .slick-dots {
            @include reset-ul;
            @include flex(center);
            @include absolute(null 0 7px 0);

            pointer-events: none;

            button {
                margin: 0 3px;
                padding: 0;
                border: none;
                font-size: 0;

                @include size(7px);

                background-color: #fff;
                border-radius: 50%;
                opacity: 0.3;
                outline: 0;
            }

            .slick-active button {
                opacity: 1;
            }
        }

        .slick-track {
            @include flex(stretch);
        }

        .slick-slide {
            height: auto;
            position: relative;

            &.quote__item {
                padding-bottom: 130px;
            }

            .quote__item__profile {
                @include absolute(null 0 0 0);

                padding: 20px 40px;
                min-height: 133px;
            }
        }
    }

    &--nobg {
        .carousel {
            &__core {
                &:before {
                    display: none;
                }
            }

            &__arrows {
                left: 10px;
                right: 10px;
            }
        }
    }

    @include media($break768M) {
        &__arrows {
            padding: 0 10px;
        }
    }


    @include media($break768) {
        &__arrows {
            left: -35px;
            right: -15px;

            &--full {
                left: 0;
                right: 0;
                padding: 0 10px;
                z-index: 1000;
            }
        }

        &__core {
            &:before {
                content: "";

                @include absolute(0 0 0 null);

                width: 160px;
                background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1));
                z-index: 100;
                pointer-events: none;
            }
        }
    }


    @include media($break992) {
        &__arrows {
            &__item.prev:hover svg {
                transform: translate(2px, 0);
            }

            &__item.next:hover svg {
                transform: translate(2px, 0);
            }
        }
    }
}
