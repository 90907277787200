.interier {
    margin-top: 40px;

    &__canvas {
        position: relative;

        &__bg {
            @include absolute(0);

            z-index: 5;
            background-color: #000;
            opacity: 0;
        }

        &__item {
            z-index: 10;

            &.is-open {
                .coverlink {
                    pointer-events: auto;
                }
            }
        }

        &__img {
            width: 100%;
        }

        &__img__detail {
            max-height: 70vh;
        }

        &__wrapper {
            position: relative;
            margin: 30px 0;
        }

        &__btn {
            margin-top: 20px;
        }

        &__nav {
            overflow: hidden;

            &__item {
                &--left {
                    float: left;
                    transform: rotate(180deg);
                }

                &--right {
                    float: right;
                }
            }
        }
    }

    &__canvas {
        width: calc(100% - 80px);
        margin: 0 auto;
        position: relative;

        &__like {
            //

        }

        &__pins {
            //

        }

        &__item {
            &__pin {
                cursor: pointer;

                @include ts;
            }

            &__more {
                position: relative;
                background-color: #f4f4f4;
                box-shadow: 3px 3px 0 rgba(195, 195, 195, 0.5);
                padding: 20px 10px;

                @include flex(space-between);

                z-index: 1000;
                pointer-events: none;

                .helper,
                .btn {
                    position: relative;
                    z-index: 10;
                    pointer-events: none;
                }

                &__img {
                    background-color: #fff;
                    text-align: center;

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }

                &__left,
                &__right {
                    width: calc(50% - 10px);
                }

                &__left {
                    img {
                        margin-bottom: 10px;
                        max-height: 200px;
                        user-select: none;
                    }
                }
            }

            &.is-open {
                z-index: 15;

                .helper,
                .btn {
                    pointer-events: auto;
                }

                .interier__canvas__item {
                    &__more {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                &.offsetclick-is-active {
                    .interier__canvas__item {
                        &__more {
                            right: auto;
                            left: 90%;
                        }
                    }
                }

                &.offsetclicktop-is-active {
                    .interier__canvas__item {
                        &__more {
                            top: 0;
                            bottom: auto;
                        }
                    }
                }

                &.offsetclickbottom-is-active {
                    .interier__canvas__item {
                        &__more {
                            top: auto;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @include media($break992M) {
        &__canvas {
            width: 100%;

            &__nav {
                margin-bottom: 20px;
            }

            &__img {
                margin-bottom: 20px;
            }

            &__item {
                margin-bottom: 30px;
                position: relative;
                left: 0 !important;
                top: 0 !important;
                pointer-events: none;

                .btn {
                    pointer-events: auto;
                }

                &__pin {
                    display: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }


    @include media($break992) {
        &__canvas {
            &__nav {
                @include absolute-center;
            }

            &__like {
                @include absolute(null 5px 10px null);

                z-index: 8;
            }

            &__pins {
                @include absolute(0);
            }

            &__item {
                @include absolute(null);

                &__pin:hover {
                    transform: translate(0, -5px);
                }

                &__more {
                    @include absolute((-50px) 100% null null);

                    width: 510px;
                    max-width: 35vw;
                    opacity: 0;

                    &__img {
                        overflow: hidden;

                        img {
                            transition: 0.3s ease all;
                        }

                        &:hover {
                            img {
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }
    }
}
